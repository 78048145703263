<template>
  <v-btn v-on:click="logout" v-bind="$attrs">
    <v-icon>mdi-checkmark</v-icon>
  </v-btn>
</template>

<script>
import firebase from 'firebase/app'
import {mapMutations} from "vuex";

export default {
  name: 'components-logout_button',
  data() {
    return {}
  },
  props: {},
  watch: {},
  methods: {
    logout() {
      firebase
          .auth()
          .signOut()
          .then(() => {
            this.clearUser();
            this.$router.push({
              name: 'login-login'
            });
          })
          .catch(error => {
            console.log(error.message);
            this.$router.push('/');
          });
    },
    ...mapMutations(['clearUser'])
  },
  computed: {},
  created() {

  },
  mounted() {
  },
  components: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
