<template>
  <div class="component">
    {{ session.totalScore }}
  </div>
</template>

<script>
export default {
  name: 'components-leaderboard_item',
  data() {
    return {}
  },
  props: {
    session: {
      required: true
    }
  },
  watch: {},
  methods: {},
  computed: {},
  created() {

  },
  mounted() {
  },
  components: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
