import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        user: undefined,
        currentTrack: undefined,
        currentNumbers: undefined,
        determineAuthPromise: undefined,
        determinedAuth: false
    },
    mutations: {
        setDetermineAuthPromise(state, promise) {
            state.determineAuthPromise = promise;
        },
        setDeterminedAuth(state) {
            state.determinedAuth = true;
        },
        setUser(state, user) {
            state.user = user;
        },
        clearUser(state) {
            state.user = undefined;
        },
        currentTrack(state, currentTrack) {
            state.currentTrack = currentTrack;
        },
        currentNumbers(state, currentNumbers) {
            state.currentNumbers = currentNumbers;
        }
    },
    getters: {
        determineAuthPromise(state) {
            return state.determineAuthPromise;
        },
        determinedAuth(state) {
            return state.determinedAuth;
        },
        user(state) {
            return state.user;
        },
        currentTrack(state) {
            return state.currentTrack;
        },
        currentNumbers(state) {
            return state.currentNumbers;
        }
    },
    actions: {},
    modules: {}
})
