<template>
  <div class="component">
    <template v-for="(number, index) in numbers">
      <slot v-bind:number="number">
        <v-btn
            height="40"
            min-width="60"
            depressed
            class="elevation-0 ma-1"
            v-bind:depressed="isIndexSelected(index)"
            v-bind:color="isIndexSelected(index) ? 'rgba(78, 82, 87, 0.25)' : 'rgb(78, 82, 87)'"
            v-on:click="onNumberClick(number, index)"><span class="number">{{ number }}</span>
        </v-btn>
      </slot>
    </template>
  </div>
</template>

<script>
import AudioService from '@/services/audio'

export default {
  name: 'components-number_selector',
  data() {
    return {
      soundButtonClick: AudioService.getSound('button-1')
    }
  },
  props: {
    numbers: {
      required: true,
      type: Array
    },
    selectedIndexes: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  watch: {},
  methods: {
    isIndexSelected(index) {
      return this.selectedIndexes.indexOf(index) > -1;
    },
    onNumberClick(number, index) {
      this.soundButtonClick.play();
      this.emitSelect(number, index);
    },
    emitSelect(number, index) {
      this.$emit('selected', {number, index});
    }
  },
  computed: {},
  components: {},
  mounted() {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.number {
  font-size: 1.5em;
  font-weight: 300;
}
</style>
