<template>
  <div class="component">
    <div class="container">
      <v-btn block v-bind:disabled="isSaving" v-on:click="generateTrack('1')">Beginner</v-btn>
      <v-btn block v-bind:disabled="isSaving" v-on:click="generateTrack('2')">Easy</v-btn>
      <v-btn block v-bind:disabled="isSaving" v-on:click="generateTrack('3')">Medium</v-btn>
      <v-btn block v-bind:disabled="isSaving" v-on:click="generateTrack('4')">Hard</v-btn>
      <v-btn block v-bind:disabled="isSaving" v-on:click="generateTrack('5')">Nightmare</v-btn>
      <v-btn block v-bind:disabled="isSaving" v-bind:to="{name: 'track-start'}">New</v-btn>
    </div>
  </div>
</template>

<script>
import ApiClient from "@/clients/api";
import _get from "lodash/get";

export default {
  name: 'views-',
  data() {
    return {
      result: undefined,
      isSaving: false
    }
  },
  props: {},
  watch: {},
  created() {

  },
  mounted() {
  },
  methods: {
    createTrack(track) {
      this.isSaving = true;

      return ApiClient.createTrack(track)
          .then((response) => {
            this.result = response.data.result;
          })
          .then(() => {
            this.$router.push({
              name: 'track-track',
              params: {
                trackId: this.trackId
              }
            });
          })
          .catch((e) => {
            console.log(e);
          })
          .finally(() => {
            this.isSaving = false;
          })
    },
    generateTrack(difficulty) {
      let settings = {
        seed: Math.random(),
        query: [],
        length: 60
      };

      switch (difficulty) {
        case '1':
          settings.query = [
            ...settings.query,
            ['number_length', '==', 3],
            ['number_end', 'in', [10, 9, 8, 7, 6, 5, 3, 4, 2, 1]]
          ]
          break;
        case '2':
          settings.query = [
            ...settings.query,
            ['number_length', '==', 4],
            ['number_end', 'in', [10, 9, 8, 7, 6, 5, 3, 4, 2, 1]]
          ]
          break;
        case '3':
          settings.query = [
            ...settings.query,
            ['number_length', '==', 4],
            ['contains_multiply_by_zero', '==', false],
            ['number_end', 'in', [10, 9, 8, 7, 6, 5, 3, 4, 2, 1]]
          ]
          break;
        case '4':
          settings.query = [
            ...settings.query,
            ['number_length', '==', 4],
            ['contains_multiply_by_zero', '==', false],
          ]
          break;
        case '5':
          settings.query = [
            ...settings.query,
            ['number_length', '==', 5],
            ['contains_multiply_by_zero', '==', false],
            // ['contains_double_occurrence', '==', false],
            // ['number_start', '==', 2],
            // ['number_end', '==', 50],
          ]
          break;
      }

      return this.createTrack(settings);
    }
  },
  computed: {
    trackId() {
      return _get(this.result, '_path.segments', []).pop();
    }
  },
  components: {},
  mixins: []
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
