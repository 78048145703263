import Vue from 'vue'
import firebase from 'firebase/app'
import store from '@/store'

import VueRouter from 'vue-router'
import HomeIndex from '@/views/home'

import Login from '@/views/login'
import LoginLogin from '@/views/login/login'

import Track from '@/views/track'
import TrackEnter from '@/views/track/enter'
import TrackCompleted from '@/views/track/completed'
import TrackStart from '@/views/track/start'
import TrackNumber from '@/views/track/number'
import TrackTrack from '@/views/track/track'

import Test from '@/views/test'

Vue.use(VueRouter)

const routes = [
    {
        path: '/track',
        meta: {
          auth: true
        },
        component: Track,
        children: [
            {
                path: '',
                name: 'track-start',
                component: TrackStart
            },
            {
                path: ':trackId/:sessionKey/completed',
                name: 'track-completed',
                component: TrackCompleted
            },
            {
                path: ':trackId',
                name: 'track-track',
                component: TrackTrack,
                children: [
                    {
                        path: 'enter',
                        name: 'track-enter',
                        component: TrackEnter
                    },
                    {
                        path: ':number',
                        name: 'track-number',
                        component: TrackNumber
                    }
                ]
            },
        ]
    },
    {
        path: '/login',
        component: Login,
        children: [
            {
                path: '',
                name: 'login-login',
                component: LoginLogin
            }
        ]
    },
    {
        path: '/',
        name: 'home',
        component: HomeIndex
    },
    {
        path: '/test',
        component: Test
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach(async (to, from, next) => {
    console.log(store.getters['determineAuthPromise']);

    await store.getters['determineAuthPromise'].then(() => {
        console.log('determineAuthPromise done');
    });

    if (to.matched.some(record => record.meta.auth)) {
        if (firebase.auth().currentUser) {
            next();
        } else {
            firebase.auth().signInAnonymously()
                .then(() => {
                    next();
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    } else {
        next();
    }
});

export default router