<template>
  <div class="component h-100">
    //numbers/query

    Number test


    <v-btn v-on:click="retrieve">retrieve</v-btn>

  </div>
</template>

<script>

import RealtimeDatabaseService from "@/services/realtimeDatabase";

export default {
  name: 'views-track-index',
  data() {
    return {}
  },
  props: {},
  watch: {},
  methods: {
    retrieve() {
      let query = [
          ['number_length', '==', 5],
          ['contains_multiply_by_zero', '==', false]
      ];

      RealtimeDatabaseService.queryNumbers(query)
        .then((docs) => {
          console.log(docs);
        });
    }
  },
  created() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  components: {},
  mixins: []
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.solution {
  cursor: pointer;
}
</style>
