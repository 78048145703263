/*
 FAT v0.6.8
 Copyright 2019 Nextapps GmbH
 Author: Thomas Wilkerling
 Released under the Apache 2.0 Licence
 https://github.com/nextapps-de/fat
*/
'use strict';(function(O,S,B){var C;(C=B.define)&&C.amd?C([],function(){return S}):(C=B.modules)?C[O.toLowerCase()]=S:"object"===typeof exports?module.exports=S:B[O]=S})("Fat",function(){function O(a,b,c,d,e,g,f,h,k,p,l,m,q,x,z,v,t,H,y){this.b=a;this.m=a.i;this.style=b;this.W=I(b);this.j=d;this.P=e;this.c=d;this.v=g;this.a=f;this.duration=h;this.o=k;this.ease=fa(k);this.time=0;this.f=p;this.l=l;this.V=c;this.g=m;this.N=q;this.U=H?"%"===g||-1!==b.indexOf("A"):"px"!==g;this.A=z;this.s=z+"_c";this.O=
    x;H&&(this.w=H,this.I=y);v&&(this.transform=v);t&&(this.filter=t)}function S(a,b,c){var d=c||-1,e;if("#"===a[0])if(a=a.toLowerCase(),4===a.length){c=J[(e=a[1])+e];var g=J[(e=a[2])+e];var f=J[(e=a[3])+e]}else c=J[a[1]+a[2]],g=J[a[3]+a[4]],f=J[a[5]+a[6]];else e=K(a,"(",")").split(","),c=parseInt(e[0],10),g=parseInt(e[1],10),f=parseInt(e[2],10),3<e.length&&(d=D(e[3])),-1!==a.indexOf("hsl")&&(0===g?g=f=a=f:(a=.5>f?f*(1+g):f+g-f*g,e=2*f-a,g=B(e,a,c+1/3),f=B(e,a,c),a=B(e,a,c-1/3)),c=255*g+.5>>0,g=255*f+
    .5>>0,f=255*a+.5>>0);a={};a[b+"R"]=c;a[b+"G"]=g;a[b+"B"]=f;-1!==d&&(a[b+"A"]=d);return a}function B(a,b,c){0>c?c+=1:1<c&&--c;return c<1/6?a+6*(b-a)*c:.5>c?b:c<2/3?a+(b-a)*(2/3-c)*6:a}function C(a){this.id=ra++;this.stack=[];this.H=sa.bind(this);this.exec=this.h=0;this.B=[];this.fps=a&&a.fps;this.playing=!a||!1!==a.start;this.direction=!0;this.ratio=1}function A(a,b,c,d){d?a.setProperty(b,c,"important"):a.setProperty(b,c)}function I(a){return a.replace(/([A-Z])/g,"-$1").toLowerCase()}function U(a){M(a)?
    a=document.querySelectorAll(a):a.constructor===Array||(a=[a]);return a}function fa(a){if(M(a))if(a){var b;if(!(b=ha[a])){if(b=T[a]||(T[a]=Y.apply(Y,ta[a]))){for(var c=new (Int16Array||Array)(E),d=0;d<E;d++)c[d]=b(d/E)*ia+.5>>0;b=c}else b=[];b=ha[a]=b}a=b}else a=[];else a=null;return a}function sa(a){var b=this.stack,c=this.B,d=b.length,e=c.length;if(d||e){this.exec=requestAnimationFrame(this.H);var g=this.h||(this.h=a);if(!(this.fps&&a-g+1<1E3/this.fps)){var f=!1;if(d){ca&&(0>Q?(Z={},Q=this.id):Q===
    this.id&&(Z={}));for(var h=0;h<d;h++){var k=b[h];k&&(f=!0,k.$(this,a)||(b[h]=null))}}if(e)for(b=0;b<e;b++)if(d=c[b])if(f=!0,!d.G||0>=(d.G-=a-g))d(a)||(c[b]=null);this.h=a;f||this.destroy()}}}function M(a){return"string"===typeof a}function t(a){return"undefined"===typeof a}function ja(a,b,c){b=D(b);return"+"===c?a+b:"-"===c?a-b:"*"===c?a*b:"/"===c?a/b:"!"===c?a===b?0:b:b}function N(a,b,c){if("custom"===b)return c||0;if(!a.nodeType||da[b])return t(c)?a[b]:c;var d=a.i||(a.i=a.style);return(t(c)?d[b]:
    c)||(a.K||(a.K=getComputedStyle(a)))[b]}function ka(a,b,c,d){if("none"===b)return b=a.substring(0,a.length-1),c[a]="scale"===b?1:0,d&&!c[b]&&(d[d.length]=b,c[b]=1),c;-1!==b.indexOf("matrix")&&(b=K(b,"(",")").split(","),b=a+"("+(parseFloat(b[ua[a]])||0)+")");a=b.replace(/, /g,",").split(" ");b={};for(var e=0,g=0;g<a.length;g++){var f=a[g],h=K(f,"(");if(h){f=K(f,"(",")").split(",");var k=f.length;2<k&&(h=h.replace("3d",""),c[h+"Z"]=f[2]);1<k?(c[h+"X"]=f[0],c[h+"Y"]=f[1]):(c[h]=f[0],h=h.substring(0,
    h.length-1));b[h]||(d&&(d[e++]=h),b[h]=1)}}return c}function la(a,b,c,d){if("none"===b)return c[a]=V[a]-1,d&&(d[d.length]=a),c;if(8>(""+b).length)return c[a]=b,d&&(d[d.length]=a),c;a=b.split(" ");b={};for(var e=0,g=0;g<a.length;g++){var f=a[g],h=K(f,"(");h&&(c[h]=K(f,"(",")"),h=h.substring(0,h.length),b[h]||(d&&(d[e++]=h),b[h]=1))}return c}function va(a,b,c){var d=a.F;if(d)var e=a.L;else a.F=d={},a.L=e=[];if(!d||t(d[b]))a=c||N(a,P||"transform"),ka(b,a,d,e);return d[b]}function wa(a,b,c){var d=a.D;
    if(d)var e=a.J;else a.D=d={},a.J=e=[];if(!d||t(d[b]))a=c||N(a,ea||"filter"),la(b,a,d,e);return d[b]||V[b]-1}function xa(a,b,c,d){var e="_"+d,g=a[e];g||(c=c||N(a,d),a[e]=g=S(c,d,1));return g[b]}function K(a,b,c){var d=a.indexOf(b);return-1!==d?c?a.substring(d+b.length,a.indexOf(c)):a.substring(0,d):""}function ya(a){if(a){var b;if(M(a)){if(b=K(a,"bezier(",")")){b=b.replace(/ /g,"");var c=b.split(",")}}else a.constructor===Array&&(b=a.join(","),c=a);b&&(T[b]||(T[b]=Y.apply(Y,c)),a=b)}return a}function za(a,
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                b,c,d){for(var e=Array(c),g=0;g<c;g++){var f=b[g],h=parseInt(f,10)-(0<g?parseInt(b[g-1],10):0);f=a[f];for(var k=Object.keys(f),p=k.length,l=0;l<p;l++){var m=k[l],q=f[m];t(q.to)&&(f[m]=q={to:q});q.duration=d/100*h+1>>0}e[g]=f}return e}var E=Math.max(screen.width,screen.height),ia=E*(window.devicePixelRatio||1),ha={},T={},Z,y,P,ma,aa,na,ea,oa;document.addEventListener("DOMContentLoaded",function(){a:{var a=getComputedStyle(document.body);if(t(a.transform)){for(var b=["webkit","moz","ms","o"],c=0,d;c<
b.length;c++)if(!t(a[(d=b[c])+"Transform"])){y=d;break a}y=void 0}else y=""}P=y&&y+"Transform";ma=y&&"-"+I(P);aa=y&&y+"Transition";na=y&&"-"+I(aa);ea=y&&y+"Filter";oa=y&&"-"+I(ea)},!1);var D=parseFloat,Q=-1,ra=0,ca=0,ta={easeIn:[.55,.085,.68,.53],easeOut:[.25,.46,.45,.94],easeInOut:[.455,.03,.515,.955],cubicIn:[.55,.055,.675,.19],cubicOut:[.215,.61,.355,1],cubicInOut:[.645,.045,.355,1],quartIn:[.895,.03,.685,.22],quartOut:[.165,.84,.44,1],quartInOut:[.77,0,.175,1],quintIn:[.755,.05,.855,.06],quintOut:[.23,
        1,.32,1],quintInOut:[.86,0,.07,1],expoIn:[.95,.05,.795,.035],expoOut:[.19,1,.22,1],expoInOut:[1,0,0,1],circIn:[.6,.04,.98,.335],circOut:[.075,.82,.165,1],circInOut:[.785,.135,.15,.86],sineIn:[.47,0,.745,.715],sineOut:[.39,.575,.565,1],sineInOut:[.445,.05,.55,.95],backIn:[.6,-.28,.735,.045],backOut:[.175,.885,.32,1.275],backInOut:[.68,-.55,.265,1.55],snap:[.1,1,.1,1]},Y=function(){function a(a,c,d,e){this.a=a;this.o=c;this.c=d;this.s=e}a.prototype.f=function(a,c){return 1-3*c+3*a};a.prototype.j=function(a,
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    c){return 3*c-6*a};a.prototype.l=function(a){return 3*a};a.prototype.m=function(a,c,d){return((this.f(c,d)*a+this.j(c,d))*a+this.l(c))*a};a.prototype.v=function(a,c,d){return 3*this.f(c,d)*a*a+2*this.j(c,d)*a+this.l(c)};a.prototype.w=function(a){for(var b=a,d=0;4>d;++d){var e=this.v(b,this.a,this.c);if(0===e)break;b-=(this.m(b,this.a,this.c)-a)/e}return b};a.prototype.A=function(a){return 0===a||1===a||this.a===this.o&&this.c===this.s?a:this.m(this.w(a),this.o,this.s)};return function(b,c,d,e){b=
    new a(b,c,d,e);return b.A.bind(b)}}(),q={fadeIn:{opacity:1},fadeOut:{opacity:0},fadeToggle:{opacity:"!=1"},slideInLeft:{translateX:0},slideOutLeft:{translateX:"-100%"},slideToggleLeft:{translateX:"!=-100%"},slideOutRight:{translateX:"100%"},slideToggleRight:{translateX:"!=100%"},slideInTop:{translateY:0},slideOutTop:{translateY:"-100%"},slideToggleTop:{translateY:"!=-100%"},slideOutBottom:{translateY:"100%"},slideToggleBottom:{translateY:"!=100%"},zoomIn:{scaleX:1,scaleY:1},zoomOut:{scaleX:0,scaleY:0},
    zoomToggle:{scaleX:"!=1",scaleY:"!=1"}};q.slideInBottom=q.slideInTop;q.slideInRight=q.slideInLeft;q.rollInLeft=q.rollInRight={rotateZ:"0deg"};q.rollOutRight={rotateZ:"720deg"};q.rollOutLeft={rotateZ:"-720deg"};q.rollToggleRight={rotateZ:"!=720deg"};q.rollToggleLeft={rotateZ:"!=-720deg"};q.blurIn={blur:"0em"};q.blurOut={blur:"5em"};q.blurToggle={blur:"!=5em"};q.scrollUp={scrollTop:0};q.scrollDown={scrollTop:"100%"};q.scrollLeft={scrollLeft:0};q.scrollRight={scrollLeft:"100%"};var m=O.prototype;m.animate=
    function(a,b,c,d){var e=this.j,g=this.P,f=this.duration,h=this.b,k=this.O,p=e===g||k&&Z[k];d=c*(d?1:-1);(c=0>d)&&0===this.time&&(this.time=f);a=this.time+=(a-(b||a))*d;b=c?0>=a:a>=f;if(!p)if(k&&(Z[k]=1),b)var l=c?e:g;else{if(k=this.ease)l=k.length?(g-e)*k[E/f*a+.5>>0]/ia:(g-e)*a/f;else if(k=this.o)l=1===k.length?k(a/f):k(a,e,g,f);l=e+l;l=this.U?(l*E+.5>>0)/E:l+.5>>0}e=this.style;p||this.c===l||(this.c=l,this.transform?h.F[e]=l+this.v:this.filter?h.D[e]=l+this.v:this.w?h["_"+this.I][e]=l:(l+=this.v,
    "custom"!==e&&(h.nodeType?this.R(e,l):h[e]=l)));e===this.transform?l=this.X():e===this.filter?l=this.T():e===this.w&&(l=this.S(this.I));this.l&&this.l.call(h,b?c?0:1:a/f,l);if(b&&(this.time=-1,this.f)){if(p=h[this.A])if(this.N--){if(f=c?--h[this.s]:++h[this.s],p=p.length,0>f||f>=p)if(h[this.s]=f=c?p-1:0,f<p)return}else h[this.A]=null;this.f.call(h)}};m.$=function(a,b){if(-1===this.time){if(this.f){b=this.b;var c=b[this.A];if(c&&(a.animate(b,c[b[this.s]],{duration:this.duration,ease:this.o,callback:this.f,
    step:this.l,force:this.a,loop:this.N}),0===this.time))return!0}this.O||(this.b[this.V]=null)}else{c=b-a.h;if(this.g)if("view"===this.g){var d=this.b.getBoundingClientRect();if(0<=d.top&&d.bottom<=window.innerHeight)this.g=0;else return!0}else{if(0<(this.g-=c))return!0;this.g=0}if(!a.playing)return a.h+=c,!0;this.animate(b,a.h,a.ratio,a.direction);return!0}};m.R=function(a,b){da[a]?this.b[a]=b:A(this.m,this.W,b,this.a)};m.update=function(a,b){if(this.c!==a)return M(a)?a=D(a):t(a)&&(a=this.j),-1!==
this.time&&(this.j=a),this.c=a,this.a=b,!0};var pa=function(a){function b(b,d){a[b]=-d;a[b+"R"]=d;a[b+"G"]=d;a[b+"B"]=d;a[b+"A"]=d;a[b+"H"]=d;a[b+"S"]=d;a[b+"L"]=d}b("color",1);b("backgroundColor",2);b("borderColor",3);return a}({}),qa=function(a){function b(b,d){a[b]=d;a[b+"X"]=d;a[b+"Y"]=d;a[b+"Z"]=d;a[b+"3d"]=d}b("translate",1);b("scale",2);b("rotate",3);b("skew",4);return a}({}),ua={scaleX:0,skewY:1,skewX:2,scaleY:3,translateX:4,translateY:5},V={blur:1,brightness:2,contrast:2,grayscale:1,"hue-rotate":1,
    invert:1,saturate:2,sepia:1},da={scrollTop:1,scrollLeft:2,scroll:3};var J={};var ba=Array(255);for(var R=0;256>R;R++){var W=R.toString(16);W.length%2&&(W="0"+W);J[W]=R;ba[R]=W}m.X=function(){for(var a=this.b.F,b=this.m,c=ma||"transform",d=this.b.L,e="",g=0,f=d.length;g<f;g++){var h=d[g],k="scale"===h?1:0,p=a[h+"X"],l=a[h+"Y"],m=a[h+"Z"];if(p||l||m)"rotate"===h&&(p&&(e+=h+"X("+(p||k)+") "),l&&(e+=h+"Y("+(l||k)+") ")),m&&D(m)!==k?e="rotate"===h?e+(h+"Z("+(m||k)+") "):e+(h+"3d("+(p||k)+","+(l||k)+","+
    m+") "):"rotate"!==h&&(e+=h+"("+(p||k)+","+(l||k)+") ")}A(b,c,e,this.a);return a};m.T=function(){for(var a=this.b.D,b=this.b.J,c="",d=0,e=b.length;d<e;d++){var g=b[d],f=(V[g]||1)-1,h=a[g];h&&h!==f&&(c+=g+"("+h+") ")}A(this.m,oa||"filter",c,this.a);return a};m.S=function(a){var b=this.b["_"+a],c=this.m,d=a.replace("C","-c"),e=b[a+"R"]||0,g=b[a+"G"]||0,f=b[a+"B"]||0;a=b[a+"A"];"%"===this.v&&(e=2.55*e+.5>>0,g=2.55*g+.5>>0,f=2.55*f+.5>>0,a&&(a/=100));A(c,d,1===a||t(a)?"#"+ba[e]+ba[g]+ba[f]:"rgba("+e+
    ","+g+","+f+","+a+")",this.a);return b};m.Y=function(a,b,c,d,e,g,f,h,k,p,l,m){t(a)?a=this.c:a=D(a);M(b)&&(b="="===b[1]?ja(a,b.substring(2),b[0]):D(b));this.j=a;this.P=b;this.duration=d;this.time=0;this.a=c;this.o!==e&&(this.ease=fa(e),this.o=e);this.f=g;this.l=f;this.g=h;k&&(this.transform=k);l&&(this.w=l,this.I=m);p&&(this.filter=p)};m=C.prototype;m.Z=function(a,b,c,d,e,g,f,h,k,p,l,m,q,x,z,v,t,H,y){var X=""+(v?va:t?wa:H?xa:N)(a,b,d,y);"auto"===X&&(X="0");d=D(X);if(M(e)){var n=e;e="="===e[1]?ja(d,
    n=e.substring(2),e[0]):D(e);g||(g=n.substring((""+e).length));"%"===g&&(n=da[b],1===n?(e*=(a.scrollHeight-a.clientHeight)/100,g=""):2===n&&(e*=(a.scrollWidth-a.clientWidth)/100,g=""))}g||(g=X.substring((""+d).length));b=new O(a,b,c,d,e,g,f,h,k,p,l,m,q,x,z,v,t,H,y);this.stack[this.stack.length]=b;x||(a[c]=b)};m.animate=function(a,b,c,d){if(b.constructor===Array){var e=b;b=b[0]}if(M(b))if(-1===b.indexOf(" "))b=q[b];else{b=b.split(" ");for(var g={},f=0,h=b.length;f<h;f++)for(var k=q[b[f]],p=Object.keys(k),
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                     l=0;l<p.length;l++)g[p[l]]=k[p[l]];b=g}if(c)if("function"===typeof c)d=c,c={};else{g=c.engine;if("css"===g)return this.transition(a,b,c);if("native"===g)return this.native(a,b,c)}else c={};a=U(a);g=c.delay;f=c.duration||400;h=ya(c.ease)||"";k=Object.keys(b);if(p=k.length)l=k[0],"%"===l[l.length-1]&&(e=za(b,k,p,f),b=e[0],k=Object.keys(b),p=k.length);d=d||c.callback||e&&function(){};l=c.step;var m=c.force,y=c.strict,x=c.init;c=c.loop||1;for(var z,v,C,H,D,A=p;0<A--;){var n=k[A];if("transform"===n){z=
    ka(n,b[n],{});n=Object.keys(z);for(var r=0,u=n.length;r<u;r++){var w=n[r];k[p++]=w;b[w]=z[w]}z=n[n.length-1]}else if(!z&&qa[n])z=n;else if("filter"===n)for(n=la(n,b[n],{}),r=Object.keys(n),u=0;u<r.length;u++)v=r[u],"hue"===v&&(v="hue-rotate"),k[p++]=v,b[v]=n[v];else if("hue"===n&&(n="hue-rotate"),!v&&V[n])v=n;else if(r=pa[n]){if(0>r){u=b[n];"object"===typeof u&&(u=u.to);u=S(u,n);var B=w=void 0;k[p++]=w=n+"R";b[w]=u[w];k[p++]=w=n+"G";b[w]=u[w];k[p++]=w=n+"B";b[w]=u[w];t(B=u[w=n+"A"])?n+="B":(k[p++]=
    w,b[w]=B,n=w);r*=-1}C||1!==r?H||2!==r?D||3!==r||(D=n):H=n:C=n}}A="_seq_"+this.id;for(n=0;n<p;n++){var J=B=w=u=void 0,K=void 0,E=void 0,F=k[n];r=b[F];var I=void 0,N=void 0;if("transform"!==F&&(u=qa[F]&&z,"filter"!==F)){"hue"===F&&(F="hue-rotate");w=V[F]&&v;var L=pa[F];if(L){if(0>L)continue;1===L?(B=C,E="color"):2===L?(J=H,E="backgroundColor"):3===L&&(K=D,E="borderColor")}if("scroll"===F)u=void 0,k[p++]=u="scrollLeft",b[u]=r[0],k[p++]=u="scrollTop",b[u]=r[1];else{r.constructor===Array?(I=r[0],N=r[2],
    r=r[1]):"object"===typeof r&&(g=r.delay||g,f=r.duration||f,h=r.ease||h,I=r.from,N=r.unit,r=r.to);L=n===p-1;for(var P="_fat_"+F+this.id,O=0,T=a.length;O<T;O++){var G=a[O],Q=y&&F+(G._id||(G._id=ca++));L&&e&&(G[A]=e,G[A+"_c"]=0);x&&0===n&&(G.K=null,z?G.F=null:C?G.ca=null:H?G.aa=null:D?G.ba=null:v&&(G.D=null));var R=!x&&!Q&&G[P];R?R.Y(I,r,m,f,h,L&&d,L&&l,g,u,w,B||J||K,E):this.Z(G,F,P,I,r,N,m,f,h,L&&d,L&&l,g,c,Q,A,u,w,B||J||K,E)}}}}this.exec||(this.exec=requestAnimationFrame(this.H));return this};m.destroy=
    function(){this.exec&&(this.exec=0,this.stack=[],this.h=0,this.B=[],ca&&Q===this.id&&(Q=-1));return this};m.create=function(a){return new C(a)};m.paint=function(a,b){b&&(a.G=b);b=this.B.length;this.B[b]=a;this.exec||(this.exec=requestAnimationFrame(this.H));return b};m.cancel=function(a){this.B[a]=null};m.ease=T;m.preset=q;m.transform=function(a,b,c,d){M(b)&&(b={transform:b});return this.animate(a,b,c,d)};m.filter=function(a,b,c,d){M(b)&&(b={filter:b});return this.animate(a,b,c,d)};m.set=function(a,
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          b,c,d){a=U(a);if(M(b))for(var e=0,g=a.length;e<g;e++){var f=a[e],h=f["_fat_"+b+this.id],k=!0;h?k=h.update(c,d):N(f,b)===c&&(k=!1);k&&(f=f.i||(f.i=f.style),A(f,I(b),c,d))}else for(d=Object.keys(b),e=0,g=d.length;e<g;e++)f=d[e],this.set(a,f,b[f],c);return this};m.seek=function(a,b){for(var c=this.stack,d=t(b),e=this.direction,g=0,f=c.length;g<f;g++){var h=c[g];h.time=d?(e?a:1-a)*h.duration:e?b:h.duration-b}return this};m.remove=function(a){var b=this.stack,c=b.length;a=U(a);for(var d=0,e=a.length;d<
e;d++)for(var g=0;g<c;g++){var f=b[g];f.b===a&&(a["_fat_"+f.style+this.id]=null,b[g]=null)}return this};m.shift=function(a){return this.seek(0,a)};m.pause=function(a){this.playing=t(a)?!1:a;return this};m.start=function(a){this.playing=t(a)?!0:a;return this};m.speed=function(a){this.ratio=a;return this};m.reset=function(){this.ratio=1;this.direction=!0;return this.seek(0)};m.finish=function(){return this.seek(1)};m.reverse=function(a){this.direction=t(a)?!this.direction:!a;return this};m.transition=
    function(a,b,c){c||(c={});a=U(a);var d=Object.keys(b),e=d.length,g=(c.duration||400)+"ms",f=c.ease||"linear",h=c.callback||!1,k=(c.delay||0)+"ms",m=c.force;for(c=0;c<e;c++){var l=d[c];if(P&&"transform"===l)b[P]=b[l],d[c]=P;else{var q=I(l);b[q]=b[l];d[c]=q}}c=aa?aa+"End":"transitionend";l={};q=0;for(var t=a.length;q<t;l={C:l.C},q++){var x=a[q],z=x.M,v=" "+g+" "+f+" "+k;v=d.join(v+",")+v;l.C=x.i||(x.i=x.style);A(l.C,na||"transition",v,m);h&&h===z||(z&&(x.removeEventListener(c,z,!1),x.M=null),h&&(x.M=
        h,x.addEventListener(c,h,!1)));requestAnimationFrame(function(a){return function(){for(var c=0;c<e;c++){var f=d[c];A(a.C,f,b[f],m)}}}(l))}return this};m.native=function(a,b,c){c||(c={});var d=Object.keys(b);a=U(a);var e=c.duration||400,g=c.ease||"linear",f=c.callback;c=c.delay||0;for(var h=d.length,k={},m=0,l=a.length;m<l;k={u:k.u},m++){k.u=a[m];for(var q={},t=0;t<h;t++){var x=d[t];q[x]=[N(k.u,x),b[x]]}k.u.animate(q,{g:c,duration:e,ease:g}).da=function(a){return function(){for(var c=0;c<h;c++){var e=
    d[c];A(a.u.i,e,b[e])}f&&f.call(a.u)}}(k)}return this};return new C}(),this);