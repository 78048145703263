<template>
  <div class="component">
    <div class="container">
      <v-btn v-bind:to="retryRoute">Try again</v-btn>

      <template v-if="retrieving">
        Retrieving
      </template>
      <template v-else>
        <track-leaderboard v-bind:track-id="trackId" />

        <track-result v-bind:track-result="trackSession" />
      </template>
    </div>
  </div>
</template>

<script>
import RealtimeDatabaseService from "@/services/realtimeDatabase";
import ResultSolution from '@/components/result-solution'
import ResultSolutions from '@/components/result-solutions'
import _get from 'lodash/get'
import TrackResult from "@/components/track-result";
import TrackLeaderboard from "@/components/track-leaderboard";

export default {
  name: 'views-track-completed',
  data() {
    return {
      retrieving: false,
      trackSession: undefined
    }
  },
  props: {},
  watch: {},
  methods: {
    retrieveTrackResult() {
      this.retrieving = true;
      return RealtimeDatabaseService.getTrackSession(this.sessionKey)
          .then((result) => {
            this.trackSession = result.val();
          })
          .catch((e) => {
            console.log(e);
          })
          .finally(() => {
            this.retrieving = false;
          })
    }
  },
  computed: {
    numberResults() {
      return _get(this.trackSession, 'numberResults');
    },
    retryRoute() {
      return {
        name: 'track-track',
        params: {
          trackId: this.trackId
        }
      }
    },
    trackId() {
      return this.$route.params.trackId;
    },
    sessionKey() {
      return this.$route.params.sessionKey;
    }
  },
  created() {
    this.retrieveTrackResult();
  },
  mounted() {
  },
  components: {
    TrackLeaderboard,
    TrackResult
  },
  mixins: []
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
