<template>
  <div class="component">
    <v-card>
      <v-card-text class="pa-0">
        <div class="d-flex">
          <div class="d-flex justify-center align-items-center pa-5">
            <span class="number">{{ result.number }}</span>
          </div>
          <div class="flex-grow-1 pa-5 text-right">
            <result-solutions v-bind:solutions="result.solutions"/>
          </div>
          <div class="d-flex justify-center align-items-center pr-5">
            <v-btn fab depressed small v-on:click="expanded = !expanded">
              <v-icon>
                <template v-if="expanded">mdi-chevron-up</template>
                <template v-else>mdi-chevron-down</template>
              </v-icon>
            </v-btn>
          </div>
        </div>
      </v-card-text>

      <template v-if="expanded">
        <v-divider class="ma-0" />
        <v-card-text class="px-5 py-2">
          <template v-for="solution in result.solutions">
            <result-solution v-bind:solution="solution"/>
          </template>
        </v-card-text>
      </template>

    </v-card>
  </div>
</template>

<script>
import ResultSolution from "@/components/result-solution";
import ResultSolutions from "@/components/result-solutions";

export default {
  name: 'components-result_card',
  data() {
    return {
      expanded: false
    }
  },
  props: {
    result: {
      required: true
    }
  },
  watch: {},
  methods: {},
  computed: {},
  created() {

  },
  mounted() {
  },
  components: {
    ResultSolution,
    ResultSolutions,
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.number {
  font-size: 2em;
  font-weight: bold;
}
</style>
