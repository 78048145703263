<template>
  <div class="component">
    <div class="d-flex">
      <div>
        {{ leftNumber }}
        {{ operator }}
        {{ rightNumber }}
        =
        {{ outcome }}
      </div>
      <div class="flex-grow-1 text-right">
        {{ solution.duration / 1000 }} seconds
      </div>
      <div class="pl-2">
        <v-tooltip left>
          <template v-slot:activator="{on}">
            <v-btn v-on="on" fab depressed x-small>
              <v-icon>mdi-information-outline</v-icon>
            </v-btn>
          </template>
          <table>
            <tr>
              <td>
                <strong>Completion:</strong>
              </td>
              <td class="text-right pl-1">
                100
              </td>
            </tr>

            <tr>
              <td>
                <strong>Time Bonus:</strong>
              </td>
              <td class="text-right pl-1">
                {{ ScoreService.timeBonus(solution) }}
              </td>
            </tr>

            <tr>
              <td>
                <strong>Time Score:</strong>
              </td>
              <td class="text-right pl-1">
                {{ ScoreService.timeScore(solution) }}
              </td>
            </tr>

            <tr>
              <td>
                <strong>Operator Bonus:</strong>
              </td>
              <td class="text-right pl-1">
                {{ ScoreService.operatorBonus(solution) }}
              </td>
            </tr>

            <tr class="border-top">
              <td>
                <strong>Combined Score:</strong>
              </td>
              <td class="text-right pl-1">
                {{ ScoreService.solutionScore(solution).sum }}
              </td>
            </tr>
          </table>

        </v-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import OPERATORS from '@/constants/operators'
import ScoreService from '@/services/score'

export default {
  name: 'components-result_solution',
  data() {
    return {
      ScoreService
    }
  },
  props: {
    solution: {
      required: true,
      type: Object
    }
  },
  watch: {},
  methods: {},
  computed: {
    operator() {
      return this.solution.operator;
    },
    leftNumber() {
      return this.solution.leftNumber;
    },
    rightNumber() {
      return this.solution.rightNumber;
    },
    outcome() {
      switch (this.operator) {
        case OPERATORS.DIVIDE:
          return this.leftNumber / this.rightNumber;
        case OPERATORS.ADD:
          return this.leftNumber + this.rightNumber;
        case OPERATORS.SUBTRACT:
          return this.leftNumber - this.rightNumber;
        case OPERATORS.MULTIPLY:
          return this.leftNumber * this.rightNumber;
      }
    }
  },
  created() {

  },
  mounted() {
  },
  components: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
