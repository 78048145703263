import firebase from 'firebase/app'

import _findLast from 'lodash/findLast'
import axios from 'axios'

const PATH_TRACK_RESULTS = 'track/results';
const PATH_TRACK_SESSIONS = 'track/sessions';

const Service = {
    userId() {
        let user = firebase.auth().currentUser;
        return user.uid;
    },
    _addUserId(data) {

        return {
            ...data,
            user: this.userId()
        }
    },
    async createTrackSession(trackId) {
        let path = `${PATH_TRACK_SESSIONS}/${this.userId()}`;
        return this.saveToList(path, {
            trackId
        });
    },
    async setResultOnTrackSession(sessionKey, numberResults) {
        let path = `${PATH_TRACK_SESSIONS}/${this.userId()}/${sessionKey}`;
        return this.save(path, {
            numberResults
        })

    },
    async getTrackSession(sessionKey) {
        let path = `${PATH_TRACK_SESSIONS}/${this.userId()}/${sessionKey}`;
        return this.get(path)
    },

    async getTrackResults(trackId) {
        let path = `${PATH_TRACK_RESULTS}/${trackId}/${this.userId()}`;
        return this.get(path)
    },

    async getTrackResultForSession(trackId, sessionKey) {
        return this.getTrackResults(trackId)
            .then((result) => {
                let match = _findLast(result.val(), (entry) => {
                    return entry.sessionKey === sessionKey;
                });

                return match;
            });
    },
    async saveTrackResult(trackId, sessionKey, result) {
        let path = `${PATH_TRACK_RESULTS}/${trackId}/${this.userId()}`;

        return this.saveToList(path, {
            trackId,
            sessionKey,
            result
        });
    },

    async queryNumbers(query) {

        return axios.post('/api/numbers/query', {
            query
        })
            .then((res) => {
                console.log(res.data);
            });
    },

    async get(path) {
        return firebase.database().ref(path).get();
    },
    async saveToList(path, data) {
        data = this._addUserId(data);
        let ref = firebase.database().ref(path);
        let newRef = ref.push();
        return newRef.set(data)
            .then(() => {
                return newRef;
            })
    },
    async save(path, data) {
        data = this._addUserId(data);
        let ref = firebase.database().ref(path);
        return ref.update(data)
            .then(() => {
                return ref;
            })
    }
};

export default Service;