<template>
  <div class="component">
    <template v-if="isAuthenticated">
      <logout-button depressed fab small />
    </template>
    <template v-else>
      <v-btn v-bind:to="{'name': 'login-login'}">
        <v-icon>mdi-checkmark</v-icon>
      </v-btn>
    </template>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import LogoutButton from '@/components/logout-button'

export default {
  name: 'components-user_bar',
  data() {
    return {}
  },
  props: {},
  watch: {},
  methods: {},
  computed: {
    isAuthenticated() {
      return !!this.user;
    },
    ...mapGetters(['user'])
  },
  created() {

  },
  mounted() {
  },
  components: {
    LogoutButton
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
