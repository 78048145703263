<template>
  <div class="component">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'views-',
  data() {
    return {}
  },
  props: {},
  watch: {},
  methods: {},
  computed: {},
  created() {

  },
  mounted() {
  },
  components: {},
  mixins: []
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
