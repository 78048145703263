<template>
  <div class="component">
    <div class="container">
      <login-form />
    </div>
  </div>
</template>

<script>
import LoginForm from '@/components/login-form'

export default {
  name: 'views-login-login',
  data() {
    return {}
  },
  props: {},
  watch: {},
  created() {

  },
  mounted() {
  },
  methods: {},
  computed: {},
  components: {
    LoginForm
  },
  mixins: []
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
