<template>
  <div class="component road-segment">
    <div class="asset" ref="asset">
      <slot></slot>
    </div>
  </div>
</template>

<script>
const Fat = require('@/libraries/fat.min')

export default {
  name: 'components-animated_view-road_segment',
  data() {
    return {
      randomizedNumber: Math.random()
    }
  },
  props: {
    bounce: {
      default() {
        return 10
      }
    },
    duration: {
      default() {
        return 500
      }
    },
    loop: undefined
  },
  watch: {},
  methods: {
    reset() {
      this.$refs['asset'].style.left = `0px`;
      this.$refs['asset'].style.top = `0px`;
    },
    animate() {
      Fat.animate(this.$refs['asset'], {
        left: `-${this.$refs['asset'].children[0].clientWidth}px`,
        top: this.$refs['asset'].children[0].clientHeight || 138
      }, {
        duration: this.duration,
        callback: () => {
          this.reset();

          window.requestAnimationFrame(() => {
            this.animate();
          });
        }
      });
    }
  },
  computed: {},
  created() {
  },
  mounted() {
    setTimeout(() => {
      window.requestAnimationFrame(() => {
        this.animate();
      });
    }, 500)
  },
  components: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.asset {
  position: absolute;
  left: 0;
  top: 0;
}
</style>
