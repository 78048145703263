<template>
  <div class="h-100">
    <template v-if="!determinedAuth">
      <div class="d-flex h-100 justify-content-center align-items-center">
        <div>
          <v-progress-circular indeterminate size="70"/>
        </div>
      </div>
    </template>
    <template v-else>
      <layout-main/>
    </template>
  </div>
</template>

<script>
import LayoutMain from '@/layouts/main'
import {mapGetters} from 'vuex'
import AudioService from '@/services/audio'
import firebase from 'firebase/app'

export default {
  name: 'app',
  data() {
    return {}
  },
  props: {},
  watch: {},
  created() {
    AudioService.addSound('ambience-outdoor-1', '/sounds/effects/ambience-outdoor-1.mp3', {
      loop: true,
      volume: 0.0
    });
    AudioService.addSound('song-1', '/sounds/music/happy_song_1.mp3', {
      loop: true
    });
    AudioService.addSound('button-1', '/sounds/effects/button-1.wav', {
      volume: 0.25
    });
    AudioService.addSound('ding-1', '/sounds/effects/ding-1.wav');
    AudioService.addSound('rev-1', '/sounds/effects/rev-1.mp3');
    AudioService.addSound('horn-1', '/sounds/effects/horn-1.mp3');
    AudioService.addSound('success-scale-1', '/sounds/effects/success-scale-1.wav', {
      volume: 0.5
    });
    AudioService.addSound('success-scale-2', '/sounds/effects/success-scale-2.wav',{
      volume: 0.5
    });
    AudioService.addSound('success-scale-3', '/sounds/effects/success-scale-3.wav',{
      volume: 0.5
    });
    AudioService.addSound('success-scale-4', '/sounds/effects/success-scale-4.wav',{
      volume: 0.5
    });
    AudioService.addSound('success-final-1', '/sounds/effects/success-final-1.wav',{
      volume: 0.5
    });

    if (!firebase.auth().currentUser) {
      this.authenticateAsGuest();
    }
  },
  mounted() {
  },
  methods: {
    authenticateAsGuest() {
      firebase.auth().signInAnonymously()
          .then(() => {
            // Signed in..
          })
          .catch((error) => {
            console.log(error);
          });
    }
  },
  computed: {
    ...mapGetters(['determinedAuth'])
  },
  components: {
    LayoutMain
  },
  mixins: []
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '~bootstrap/dist/css/bootstrap.css';

</style>

