<template>
  <v-app>
    <v-app-bar
        app
        flat
        color="transparent"
    >
      <div class="d-flex justify-center w-100">
        <div>
          <v-btn fab small depressed>
            <v-icon>mdi-information-outline</v-icon>
          </v-btn>

          <v-snackbar
              v-model="showCopiedToClipboard"
          >
            Copied to your clipboard!

            <template v-slot:action="{ attrs }">
              <v-btn
                  text
                  v-bind="attrs"
                  @click="showCopiedToClipboard = false"
              >
                Close
              </v-btn>
            </template>
          </v-snackbar>

          <v-btn fab small depressed v-on:click="copyUrl">
            <v-icon>mdi-share-variant</v-icon>
          </v-btn>
        </div>
        <div class="flex-grow-1 text-center">
          <div class="d-inline-block app-title" v-on:click="clickedIt">
            <span class="light">Zero</span>
            <span class="bold">It</span>
            <span class="light">Out</span>
          </div>
        </div>
        <div>
          <user-bar/>
        </div>
      </div>
    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import UserBar from '@/components/user-bar'

export default {
  name: 'layout-main',
  data() {
    return {
      showCopiedToClipboard: false
    }
  },
  props: {},
  watch: {},
  created() {
  },
  mounted() {

  },
  methods: {
    copyUrl() {
      if (navigator.share) {
        navigator.share({
          url: window.location.href
        })
      } else {
        navigator.clipboard.writeText(window.location.href);
        this.showCopiedToClipboard = true;
      }
    },
    clickedIt() {
      this.$router.push({
        name: 'home'
      })
    }
  },
  computed: {},
  components: {
    UserBar
  },
  mixins: []
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.app-title {
  font-size: 1.5em;
  color: #adbac7;
  text-transform: uppercase;

  .light {
    font-weight: 300;
  }

  .bold {
    font-weight: bolder;
  }
}
</style>
