<template>
  <div class="component w-100 h-100">
    <div class="d-flex flex-column h-100">
      <transition
          enter-active-class="animate__animated animate__fadeIn"
          leave-active-class="animate__animated animate__fadeOut"
      >
        <div class="loader position-absolute h-100 w-100 d-flex justify-center align-center"
             v-if="isRetrieving"
        >
          <div>
            <v-progress-circular indeterminate size="100"
            ></v-progress-circular>
          </div>
        </div>
      </transition>
      <div class="flex-grow-1">
        <transition
            
        >
          <template v-if="ended">
          </template>
          <template v-else-if="started">
            <router-view v-on:completed="onNumberCompleted"
                         v-bind:key="currentNumber"/>
          </template>
          <template v-else-if="completed">
          </template>
          <template v-else-if="track">
          </template>
          <template v-else>
          </template>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import ApiClient from '@/clients/api'
import RealtimeDatabaseService from '@/services/realtimeDatabase'
import moment from 'moment'
import AnimatedView from '@/components/animated-view'
import AudioService from '@/services/audio'
import TimerRadial from "@/components/timer-radial";


const GAME_TYPES = {
  TIME_RUNS_OUT: 'TIME_RUNS_OUT',
  FASTEST_SOLVES: 'FASTEST_SOLVES',
};

export default {
  name: 'views-track-hash',
  data() {
    return {
      musicEnabled: false,
      gameType: GAME_TYPES.FASTEST_SOLVES,


      numbers: undefined,
      track: undefined,
      sessionKey: undefined,

      isRetrieving: false,
      startTime: undefined,
      currentTime: undefined,

      started: false,
      ended: false,
      completed: false,

      soundRev: AudioService.getSound('rev-1'),
      soundMusic: AudioService.getSound('song-1'),
      soundHorn: AudioService.getSound('horn-1'),
      soundAmbience: AudioService.getSound('ambience-outdoor-1'),

      timerInterval: undefined,
      // trackTime: 30000,
      trackTime: 60000000,
      numberResults: []
    }
  },
  props: {},
  watch: {
    timeLeft: {
      handler(newValue) {
        if (newValue < 0) {
          // this.onTrackEnded();
        }
      }
    }
  },
  methods: {
    timeLeftFormatter(value) {
      return Math.floor((this.trackTime - value) / 100) / 10;
    },
    startAmbience() {
      this.soundAmbience.play();
      this.soundAmbience.fade(0, 0.5, 3000);
    },
    startMusic() {
      this.soundMusic.loop = true;
      this.soundMusic.play();
      this.soundMusic.fade(0, 1, 3000);
    },
    stopAmbience(hard) {
      if (hard) {
        this.soundAmbience.stop();
      } else {
        this.soundAmbience.fade(0.5, 0, 1000);
        setTimeout(() => {
          this.soundAmbience.stop();
        }, 1000);
      }
    },
    stopMusic(hard) {
      if (hard) {
        this.soundMusic.stop();
      } else {
        this.soundMusic.fade(1, 0, 1000);
        setTimeout(() => {
          this.soundMusic.stop();
        }, 1000);
      }
    },
    onTrackEnded() {
      clearInterval(this.timerInterval);
      this.ended = true;
      this.setResultOnTrackSession()
          .then(() => {
            this.$router.replace({
              name: 'track-completed',
              params: {
                id: this.trackId,
                sessionKey: this.sessionKey
              }
            });
          })
    },
    setResultOnTrackSession() {
      return RealtimeDatabaseService.setResultOnTrackSession(this.sessionKey, this.numberResults);
    },
    timerTick() {
      this.currentTime = moment();
    },
    startGameType() {
      switch (this.gameType) {
        case GAME_TYPES.FASTEST_SOLVES:
          return Promise.resolve();
        case GAME_TYPES.TIME_RUNS_OUT:
          return Promise.resolve();
      }
    },
    startTrack() {
      this.startTime = moment();
      this.currentTime = moment();

      this.timerInterval = setInterval(() => {
        this.timerTick();
      }, 250);

      this.startGameType()
          .then(() => {
            this.started = true;
            this.navigateToNextNumber();
          })
    },
    retrieve() {
      this.isRetrieving = true;

      return ApiClient.getTrackNumbers(this.trackId)
          .then((result) => {
            this.numbers = result.data.numbers;
            this.track = result.data.track;

            this.$store.commit('currentTrack', this.track);
            this.$store.commit('currentNumbers', this.numbers.slice(0));
          })
          .catch((e) => {
            console.log(e);
          })
          .finally(() => {
            this.isRetrieving = false;
          })
    },
    navigateToNextNumber() {
      let nextNumber = this.numbers.shift();

      this.$router.replace({
        name: 'track-number',
        params: {
          id: this.trackId,
          number: nextNumber.number.toString()
        }
      });
    },
    onNumberCompleted(numberResult) {
      this.numberResults.push(numberResult);
      AudioService.getSound('success-final-1').play();
      this.navigateToNextNumber();
    }
  },
  computed: {
    animatedBackgroundContentStyle() {
      let scaleY = window.innerHeight / 500;
      let scaleX = window.innerWidth / 500;
      let scale = scaleX > scaleY ? scaleX : scaleY;

      return {
        transform: `scale(${scale})`
      }
    },
    timeLeft() {
      return this.trackTime - this.timePassed;
    },
    timePassed() {
      if (!this.currentTime || !this.startTime) {
        return;
      }
      return this.currentTime.diff(this.startTime);
    },
    trackId() {
      return this.$route.params.trackId;
    },
    currentNumber() {
      return this.$route.params.number;
    }
  },
  created() {
    if (this.currentNumber) {
      // Should not be created with current number
      return this.$router.replace({
        name: 'track-track',
        params: {
          id: this.trackId
        }
      })
    }
  },
  mounted() {
    if (this.musicEnabled) {
      this.startMusic();
      this.startAmbience();
    }

    RealtimeDatabaseService.createTrackSession(this.trackId)
        .then((session) => {
          console.log(session);
          this.sessionKey = session.getKey();
        })
        .then(() => {
          return this.retrieve();
        })
        .then(() => {
          this.startTrack();
        })
  },
  beforeDestroy() {
    if (this.timerInterval) {
      clearImmediate(this.timerInterval);
    }

    if (this.musicEnabled) {
      this.stopMusic();
      this.stopAmbience();
    }
  },
  components: {
    AnimatedView,
    TimerRadial
  },
  mixins: []
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.animated-background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  background: green;

  .content {
    transform: scale(1);
    transition: transform 5s;

    &.zoomed {
      transform: scale(5) !important;
    }
  }
}

</style>
