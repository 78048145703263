<template>
  <div class="component car" v-on:click="animate">
    <slot></slot>
  </div>
</template>

<script>
const Fat = require('@/libraries/fat.min')

export default {
  name: 'components-animated_view-car',
  data() {
    return {
      randomizedNumber: Math.random()
    }
  },
  props: {
    bounce: {
      default() {
        return 10
      }
    },
    duration: {
      default() {
        return 500
      }
    },
    loop: undefined
  },
  watch: {},
  methods: {
    reset() {

    },
    animate() {
      Fat.animate(this.$el, {
        left: `${(-this.bounce * Math.random()) + (this.bounce * Math.random())}px`,
        top: `${(-this.bounce * Math.random()) + (this.bounce * Math.random())}px`
      }, {
        duration: this.duration,
        callback: () => {
          this.reset();

          if (this.loop) {
            window.requestAnimationFrame(() => {
              this.animate();
            });
          }
        }
      });
    }
  },
  computed: {

  },
  created() {
  },
  mounted() {
    this.reset();
    window.requestAnimationFrame(() => {
      this.animate();
    });
  },
  components: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.car {
  position: absolute;
  left: 0;
  top: 0;
}
</style>
