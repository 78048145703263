<template>
  <div class="component d-flex justify-center">
    <template v-for="numberPart in numbersWithState">
      <div class="number d-flex justify-center align-center animate__animated animate__slideInLeft"
           v-bind:class="{'original': numberPart.original, 'used': numberPart.used}">
        {{ numberPart.number }}
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'components-simple_number',
  data() {
    return {}
  },
  props: {
    allNumbers: undefined,
    usedNumbers: undefined,
    availableNumbers: undefined,
    numbers: {
      required: true
    }
  },
  watch: {},
  methods: {
    numberClasses(numbersWithState) {
      let classes = [];
      if (numbersWithState.used) {
        classes.push('used');
      }
      if (numbersWithState.original) {
        classes.push('original');
      }
      console.log(numbersWithState, classes);

      return classes;
    }
  },
  computed: {
    numbersWithState() {
      let result = [];
      let numbersInt = this.numbers.slice(0);
      this.allNumbers.forEach((number) => {
        let originalNumberIndex = numbersInt.indexOf(number);
        let original = false;
        if (originalNumberIndex > -1) {
          numbersInt.splice(originalNumberIndex, 1);
          original = true;
          console.log(number, originalNumberIndex, original);
        }

        let usedIndex = this.usedNumbers.indexOf(number);
        let used = false;
        if (usedIndex > -1) {
          this.usedNumbers.splice(usedIndex, 1);
          used = true;
        }

        result.push({
          number,
          used,
          original
        })
      });

      return result;
    }
  },
  created() {

  },
  mounted() {
  },
  components: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.number {
  margin: 5px;
  min-width: 1em;
  height: 2em;
  font-size: 2em;
  border-radius: 2px;
  color: orange;
  font-weight: bold;
  transition: opacity .25s;

  &.original {
    color: white;
  }

  &.used {
    opacity: 0.25;
  }
}
</style>
