import {Howl} from "howler";

const PATH_TRACK_RESULTS = 'track/results';
const PATH_TRACK_SESSIONS = 'track/sessions';

const Service = {
    sounds: {},
    addSound(key, path, options) {
        if (this.sounds[key]) {
            return;
        }

        let o = {
            src: [path],
            ...options
        };

        this.sounds[key] = new Howl(o);
    },
    getSound(key) {
        console.log('key');

        return this.sounds[key];
    }
};

export default Service;