<template>
  <div class="component">
    <div class="container">
      <h2>Track Start</h2>
      <v-card>
        <v-card-text>
          <track-creator v-bind:loading="isSaving" v-bind:aria-disabled="isSaving" v-model="track" />
        </v-card-text>
        <v-card-actions>
          <v-btn v-on:click="createTrack" v-bind:disabled="!track">Save</v-btn>
        </v-card-actions>
      </v-card>

      <pre>{{ trackId }}</pre>
      <pre>{{ result }}</pre>
    </div>
  </div>
</template>

<script>
import TrackCreator from '@/components/track-creator'
import ApiClient from '@/clients/api'
import _get from 'lodash/get'

export default {
  name: 'views-track-index',
  data() {
    return {
      isSaving: false,
      result: undefined,
      track: undefined
    }
  },
  props: {},
  watch: {},
  created() {

  },
  mounted() {
  },
  methods: {
    createTrack() {
      this.isSaving = true;

      return ApiClient.createTrack(this.track)
        .then((response) => {
          this.result = response.data.result;
        })
          .then(() => {
            this.$router.push({
              name: 'track-track',
              params: {
                trackId: this.trackId
              }
            });
          })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          this.isSaving = false;
        })
    }
  },
  computed: {
    trackId() {
      return _get(this.result, '_path.segments', []).pop();
    }
  },
  components: {
    TrackCreator
  },
  mixins: []
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
