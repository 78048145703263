import axios from 'axios'

const root = '/api';

const Client = {
    getNumbers(query, size = 30, seed = 'random') {
        return this.post(`${root}/numbers/query`, {
            query
        });
    },
    createTrack(track) {
        track.query = JSON.stringify(track.query);

        return this.post(`${root}/tracks`, {
            track
        });
    },
    getTrack(trackId) {
        return this.get(`${root}/tracks/${trackId}`);
    },
    getTrackResults(trackId) {
        return this.get(`${root}/tracks/${trackId}/results`);
    },
    getTracks() {
        return this.get(`${root}/tracks`);
    },
    getTrackNumbers(trackId) {
        return this.get(`${root}/tracks/${trackId}/numbers`);
    },
    get(path, headers = {}) {
        return axios.get(path, {
            headers: headers
        })
    },
    patch(path, body, headers = {}) {
        return axios.patch(path, {
            headers: headers
        })
    },
    post(path, body, headers = {}) {
        return axios.post(path, body, {
            headers: headers
        })
    }
};

export default Client;