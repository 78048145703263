<template>
  <div class="component">
    <div class="row no-gutters">
      <div class="col-12">
        <v-text-field v-bind:loading="loading" v-bind:disabled="disabled" outlined dense hide-details v-model="seed" label="Seed"/>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <v-select v-bind:items="filterOptions" v-bind:loading="loading" v-bind:disabled="disabled" outlined dense hide-details v-model="containsMultiplyByZero" label="Contains Multiply By Zero"/>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <v-select v-bind:items="filterOptions" v-bind:loading="loading" v-bind:disabled="disabled" outlined dense hide-details v-model="containsDoubleOccurrence" label="Contains Double Occurrence" />
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <v-select v-bind:items="filterOptions" v-bind:loading="loading" v-bind:disabled="disabled" outlined dense hide-details v-model="containsZero" label="Contains Zero"/>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <v-text-field v-bind:loading="loading" v-bind:disabled="disabled" outlined dense hide-details type="number" v-model="length" label="Length"/>
      </div>
    </div>

    <div>
      {{ formData }}

      {{ filterOptions }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'components-track_creator',
  data() {
    return {
      seed: Math.random(),
      containsMultiplyByZero: undefined,
      containsDoubleOccurrence: undefined,
      length: 3,
      containsZero: undefined
    }
  },
  props: {
    loading: undefined,
    disabled: undefined,
    value: undefined
  },
  watch: {
    formData: {
      deep: true,
      handler() {
        this.$emit('input', this.formData);
      }
    }
  },
  methods: {},
  computed: {
    filterOptions() {
      return [
        {
          text: 'None',
          value: null
        },
        {
          text: 'Yes',
          value: true
        },
        {
          text: 'No',
          value: false
        }
      ]
    },
    formData() {
      return {
        "seed": this.seed,
        "contains_multiply_by_zero": this.containsMultiplyByZero,
        "contains_double_occurrence": this.containsDoubleOccurrence,
        "length": this.length,
        "contains_zero": this.containsZero
      }
    }
  },
  created() {

  },
  mounted() {
  },
  components: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
