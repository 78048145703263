import OPERATORS from '@/constants/operators'

const Service = {
    containsZero(solution) {
        return (solution.leftNumber === 0 || solution.rightNumber === 0);
    },
    timeBonus(solution, multiplier = 1) {
        if (this.containsZero(solution)) {
            return 0;
        }

        let bonus = 0;
        let duration = parseInt(solution.duration);

        if (duration < 1000) {
            bonus = 3000;
        } else if (duration < 2000) {
            bonus = 2000;
        } else if (duration < 3000) {
            bonus = 1000;
        }

        return bonus * multiplier;
    },
    timeScore(solution) {
        let result = Math.floor((5000 - solution.duration) / 10);
        return result > 0 ? result : 0;
    },
    operatorBonus(solution) {
        if (this.containsZero(solution)) {
            return 0;
        }

        switch (solution.operator) {
            case OPERATORS.ADD:
                return 2000;
            case OPERATORS.SUBTRACT:
                return 1000;
            case OPERATORS.MULTIPLY:
                return 3000;
            case OPERATORS.DIVIDE:
                return 4000;
            default:
                console.log(solution);
                return 0;
        }
    },
    solutionScore(solution) {
        let timeBonus = this.timeBonus(solution);
        let operatorBonus = this.operatorBonus(solution);
        let score = 100;
        let timeScore = this.timeScore(solution);
        let sum = timeBonus + score + operatorBonus + score + timeScore;

        return {
            timeBonus,
            operatorBonus,
            score,
            timeScore,
            sum
        }
    },
    solutionsScore(solutions) {
        let results = [];
        solutions.forEach((solution) => {
            results.push(this.solutionScore(solution));
        });

        return results;
    },
    trackScore(numbers) {
        let results = [];
        numbers.forEach((number) => {
            number.solutions.forEach((solution) => {
                results.push(this.solutionScore(solution));
            });
        })

        return results;
    },
};

export default Service;