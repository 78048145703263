<template>
  <div class="component">
    <template v-for="operator in operators">
      <v-btn v-bind:key="operator"
             height="40"
             min-width="60"
             depressed
             class="elevation-0 ma-1"
             v-bind:depressed="isOperatorDisabled(operator)"
             v-bind:color="isOperatorDisabled(operator) ? 'rgba(78, 82, 87, 0.75)' : 'rgb(78, 82, 87)'"
             v-on:click="onOperatorClick(operator)">
        <span class="operator">
          <template v-if="operator === OPERATORS.ADD">
            <v-icon>mdi-plus</v-icon>
          </template>
          <template v-else-if="operator === OPERATORS.DIVIDE">
            <v-icon>mdi-slash-forward</v-icon>
          </template>
          <template v-else-if="operator === OPERATORS.MULTIPLY">
            <v-icon>mdi-close</v-icon>
          </template>
          <template v-else-if="operator === OPERATORS.SUBTRACT">
            <v-icon>mdi-minus</v-icon>
          </template>
        </span>
      </v-btn>
    </template>
  </div>
</template>

<script>
import OPERATORS from '@/constants/operators'
import {Howl} from "howler";
import AudioService from "@/services/audio";
import InputButton from "@/components/input-button";

export default {
  name: 'components-operators',
  data() {
    return {
      OPERATORS,
      soundButtonClick: AudioService.getSound('button-1')
    }
  },
  props: {
    disabledOperators: {
      type: Array,
      default() {
        return [];
      }
    },
    operators: {
      type: Array,
      default() {
        return [
          OPERATORS.ADD,
          OPERATORS.DIVIDE,
          OPERATORS.MULTIPLY,
          OPERATORS.SUBTRACT
        ];
      }
    }
  },
  watch: {},
  methods: {
    isOperatorDisabled(operator) {
      return this.disabledOperators.indexOf(operator) > -1;
    },
    onOperatorClick(operator) {
      this.soundButtonClick.play();
      this.emitSelected(operator);
    },
    emitSelected(operator) {
      this.$emit('selected', operator);
    }
  },
  computed: {},
  components: {
    InputButton
  },
  mounted() {
  },
  beforeDestroy() {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.operator {
  font-weight: bold;
  font-size: 1.5em;
}
</style>
