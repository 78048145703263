<template>
  <div class="component">
    <div class="d-flex">
      <div class="number d-flex justify-center align-center">
        <div>
          {{ leftNumber }}
        </div>
      </div>
      <div class="operator d-flex justify-center align-center">
        <div>
          {{ operator }}
        </div>
      </div>
      <div class="number d-flex justify-center align-center">
        <div>
          {{ rightNumber }}
        </div>
      </div>
      <div class="equals d-flex justify-center align-center">
        <div>
          =
        </div>
      </div>
      <div class="outcome d-flex justify-center align-center">
        {{ outcome }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'components-live_equation',
  data() {
    return {}
  },
  props: {
    leftNumber: undefined,
    rightNumber: undefined,
    operator: undefined,
    outcome: undefined
  },
  watch: {},
  methods: {},
  computed: {},
  components: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.outcome,
.equals,
.operator,
.number {
  margin: 5px;
  background: rgba(0, 0, 0, 0.1);
  min-width: 2em;
  height: 2em;
  font-size: 2em;
  border-radius: 2px;
  color: white;
  font-weight: bold;
  border: 1px solid #444c56;
}

.equals {
  color: rgba(255, 255, 255, 0.25);
}

.operator {
  color: rgba(255, 255, 255, 0.25);
}

</style>
