<template>
  <v-btn height="70"
         depressed
         v-bind="$attrs"
         class="elevation-0 ma-2">
    <slot></slot>
  </v-btn>
</template>

<script>
export default {
  name: 'components-',
  data() {
    return {}
  },
  props: {},
  watch: {},
  methods: {},
  computed: {},
  created() {

  },
  mounted() {
  },
  components: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
