<template>
  <div class="component animation-pocket">
    <template v-for="(asset, assetIndex) in assets">
      <animated-asset v-bind:parent-height="canvasHeight"
                      v-bind:parent-width="canvasWidth + (width * scale)"
                      v-bind:delay-range="duration / 4"
                      v-bind:minimum-delay="duration / 2"
                      v-bind:randomize="false"
                      v-bind:duration="duration"
                      v-on:reset="onAnimatedAssetReset(assetIndex)"
                      v-on:half-way="onAnimatedAssetHalfway"
                      v-bind:ref="`animation_asset_${assetIndex}`"
                      v-bind:auto-start="false"
                      v-bind:key="`animation_asset_${assetIndex}`"
                      class="animated-asset"
      >
        <img :src="asset.src" v-bind:key="asset.src" v-bind:width="asset.width * scale"/>
      </animated-asset>
    </template>
  </div>
</template>

<script>
import _get from "lodash/get";
import AnimatedAsset from "@/components/animated-view/animated-asset";

export default {
  name: 'components-animated_view-animation_pocket',
  data() {
    return {
      currentAssetIndex: 0,
      assets: [],
      road: {
        src: require('../../../../assets/road.svg'),
        width: 120
      },
      car: {
        src: require('../../../../assets/car-1.svg'),
        width: 30
      },
      car1: {
        src: require('../../../../assets/car-1.svg'),
        width: 30
      },
      car2: {
        src: require('../../../../assets/car-2.svg'),
        width: 30
      },
      house1: {
        src: require('../../../../assets/house-1.svg'),
        width: 55
      },
      house2: {
        src: require('../../../../assets/house-2.svg'),
        width: 55
      },
      house3: {
        src: require('../../../../assets/house-3.svg'),
        width: 55
      },
      sign1: {
        src: require('../../../../assets/sign-1.svg'),
        width: 55
      },
      tree1: {
        src: require('../../../../assets/tree-1.svg'),
        width: 40
      },
      tree2: {
        src: require('../../../../assets/tree-2.svg'),
        width: 40
      },
      tree3: {
        src: require('../../../../assets/tree-3.svg'),
        width: 40
      },
      tree4: {
        src: require('../../../../assets/tree-4.svg'),
        width: 40
      },
      tree5: {
        src: require('../../../../assets/tree-5.svg'),
        width: 40
      },
      tree6: {
        src: require('../../../../assets/tree-6.svg'),
        width: 40
      },
      tree7: {
        src: require('../../../../assets/tree-7.svg'),
        width: 40
      },
      tree8: {
        src: require('../../../../assets/tree-8.svg'),
        width: 40
      }
    }
  },
  props: {
    assetCount: {
      required: true,
    },
    width: {
      required: true
    },
    scale: {
      required: true
    },
    duration: {
      required: true
    },
    canvasWidth: {
      required: true
    },
    canvasHeight: {
      required: true
    },
    pocket: {
      required: true
    },
    roadSideAssets: {
      default() {
        return false
      }
    }
  },
  watch: {},
  methods: {
    setAssets() {
      let assets = [];
      for (let assetIndex = 0; assetIndex < this.assetCount; assetIndex++) {
        if(this.roadSideAssets) {
          assets.push(this.randomRoadSideAsset());
        } else {
          assets.push(this.randomTree());
        }

      }

      this.assets = assets;
    },
    randomRoadSideAsset() {
      let assets = [
        this.house1,
        this.house2,
        this.house3
      ]

      return assets[Math.floor(assets.length * Math.random())];
    },
    randomTree() {
      let assets = [
        this.tree1,
        this.tree2,
        this.tree3,
        this.tree4,
        this.tree5,
        this.tree6,
        this.tree7,
        this.tree8
      ]

      let randomIndex = Math.floor(assets.length * Math.random());
      return assets[randomIndex];
    },
    onAnimatedAssetHalfway() {
      this.currentAssetIndex++;
      if (this.currentAssetIndex > this.assets.length - 1) {
        this.currentAssetIndex = 0;
      }

      window.requestAnimationFrame(() => {
        this.startAnimatedAsset(this.currentAssetIndex);
      });
    },
    onAnimatedAssetReset(assetIndex) {
      let newAsset;
      if (this.roadSideAssets) {
        newAsset = this.randomRoadSideAsset();
      } else {
        newAsset = this.randomTree();
      }

      this.$set(this.assets, assetIndex, newAsset);
    },
    startAnimatedAsset(index) {
      let animationAsset = this.$refs[`animation_asset_${index}`][0];
      animationAsset.animate();
    }
  },
  computed: {
  },
  created() {
    this.setAssets();
  },
  mounted() {
    this.startAnimatedAsset(this.currentAssetIndex);
  },
  components: {
    AnimatedAsset
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.animated-asset{
  width: 30px;
  height: 65px;
}
</style>
