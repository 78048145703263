<template>
  <div class="component animated-asset" v-on:click="animate">
    <div class="content">
      <slot></slot>
    </div>

    <div class="debug" v-if="debug">
      {{ topOffset }}
    </div>
  </div>
</template>

<script>
const Fat = require('@/libraries/fat.min')

export default {
  name: 'components-animated_view-animated_asset',
  data() {
    return {
      randomizedNumber: Math.random()
    }
  },
  props: {
    autoStart: {
      default() {
        return true
      }
    },
    reverse: undefined,
    debug: undefined,
    parentHeight: {
      required: true
    },
    parentWidth: {
      required: true
    },
    duration: {
      required: true
    },
    delay: {
      default() {
        return 0;
      }
    },
    delayRange: undefined,
    loop: undefined,
    randomize: {
      default() {
        return false;
      }
    }
  },
  watch: {},
  methods: {
    calculatedDelay() {
      if (this.delayRange) {
        return this.delayRange * Math.random();
      }

      return this.delay;
    },
    reset() {
      this.$emit('reset');

      if (this.reverse) {
        this.$el.style.left = this.animationEnd.left;
        this.$el.style.top = this.animationEnd.top;
      } else {
        this.$el.style.left = this.animationStart.left;
        this.$el.style.top = this.animationStart.top;
      }
    },
    animate() {
      let target = this.reverse ? this.animationStart : this.animationEnd;
      let delay = this.calculatedDelay();
      let halfwayPoint = delay + (this.duration / 2);
      setTimeout(() => {
        this.$emit('half-way');
      }, halfwayPoint);

      Fat.animate(this.$el, target, {
        delay: delay,
        duration: this.duration,
        callback: () => {
          this.reset();

          if (this.loop) {
            window.requestAnimationFrame(() => {
              this.animate();
            });
          }
        }
      });
    }
  },
  computed: {
    animationStart() {
      return {
        left: `0px`,
        top: `0px`
      };
    },
    animationEnd() {
      return {
        left: `-${this.parentWidth + 30}px`,
        top: `${(this.parentWidth - (this.parentWidth / 2.55))}px`
      };
    },
    topOffset() {
      if (this.randomize) {
        let minTop = -this.parentWidth;
        let maxTop = this.parentHeight;
        let result = ((maxTop - minTop) * this.randomizedNumber) + minTop;
        if (this.noGoTop && result > this.noGoTop[0] && result < this.noGoTop[1]) {
          console.log('coll', result);
          result = this.noGoTop[0];
        }
        return result;
      }

      return this.top;
    }
  },
  created() {
  },
  mounted() {
    if (this.reverse) {
      this.$el.style.left = this.animationEnd.left;
      this.$el.style.top = this.animationEnd.top;
    }

    if (this.autoStart) {
      window.requestAnimationFrame(() => {
        this.animate();
      });
    }
  },
  components: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.animated-asset {
  position: absolute;
  left: 100%;

  .content {
    position: absolute;
    bottom: 10px;
    right: 0;
  }
}

.debug {
  position: absolute;
  left: 100%;
}
</style>
