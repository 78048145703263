<template>
  <div class="component">
    <v-card class="mb-3">
      <v-card-text>
        <div class="text-center pa-5">
          <h6>Leaderboard</h6>
          <template v-if="isRetrieving">
            <v-progress-circular indeterminate size="70"/>
          </template>
          <template v-else>
            <ol class="text-left">
              <template v-for="leaderboardScore in leaderboardScores">
                <li>
                  <leaderboard-item v-bind:session="leaderboardScore"></leaderboard-item>
                </li>
              </template>
            </ol>
          </template>

        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import LeaderboardItem from "@/components/leaderboard-item";
import ScoreService from "@/services/score";
import ApiClient from '@/clients/api'

export default {
  name: 'components-track_leaderboard',
  data() {
    return {
      ScoreService,
      isRetrieving: false,
      sessions: []
    }
  },
  props: {
    trackId: {
      required: true
    }
  },
  watch: {},
  methods: {
    retrieve() {
      this.isRetrieving = true;
      ApiClient.getTrackResults(this.trackId)
          .then((res) => {
            this.sessions = res.data.sessions;
          })
          .catch((e) => {

          })
          .finally(() => {
            this.isRetrieving = false;
          })
    }
  },
  computed: {
    leaderboardScores() {
      return this.sessions
          .map((session) => {
            let numberResults = Object.values(session.numberResults).map((numberResult) => {
              numberResult.solutions = Object.values(numberResult.solutions);
              return numberResult;
            });
            let scores = ScoreService.trackScore(numberResults);
            return {
              scores: scores,
              totalScore: scores.reduce((accumulator, current) => {
                return accumulator + current.sum;
              }, 0),
              trackId: session.trackId,
              user: session.user
            }
          })
          .sort((a, b) => {
            if (a.totalScore > b.totalScore) {
              return -1;
            }

            if (a.totalScore < b.totalScore) {
              return 1;
            }

            return 0;
          });
    }
  },
  created() {
    this.retrieve();
  },
  mounted() {
  },
  components: {
    LeaderboardItem
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.total-sum {
  font-size: 3em;
  font-weight: bold;
}
</style>
