<template>
  <div class="component h-100">
    <div class="d-flex flex-column h-100">
      <div class="text-center py-3">
        <simple-number v-bind:numbers="currentNumber.number"
                       v-bind:all-numbers="allNumbers"
                       v-bind:used-numbers="usedNumbers"
                       v-bind:available-numbers="usedNumbers"
        />
      </div>

      <transition-group
      >
        <div class="d-flex justify-center mb-1"
             v-bind:key="`${solution.leftNumber}${solution.operator}${solution.rightNumber}${solution.outcome}`"
             v-for="(solution, index) in solutions">
          <div class="solution" v-on:click="onClickSolution(index)">
            <live-equation
                v-bind:left-number="solution.leftNumber"
                v-bind:right-number="solution.rightNumber"
                v-bind:operator="solution.operator"
                v-bind:outcome="solution.outcome"
            />
          </div>
        </div>
      </transition-group>

      <div class="d-flex justify-center mb-1">
        <live-equation
            v-bind:left-number="leftNumber"
            v-bind:right-number="rightNumber"
            v-bind:operator="operator"/>
      </div>

      <div class="flex-grow-1">

      </div>
      <div class="pa-5">

        <div class="d-flex justify-center mb-1" v-if="showHint">
          <template v-if="!firstRemainingSolutionSteps">
            No possible solution
          </template>
          <template v-else>
            {{ firstRemainingSolutionSteps }}
          </template>
        </div>

        <div class="d-flex justify-center">
          <number-selector v-bind:numbers="availableNumbers"
                           v-bind:selected-indexes="selectedNumberIndexes"
                           v-on:selected="onNumberSelected"/>
        </div>

        <div class="d-flex justify-center">
          <div>
            <div>
              <operators v-bind:disabled-operators="[operator]"
                         v-on:selected="onOperatorSelected"
                         class="d-inline"
              />
            </div>
            <div class="d-flex">
              <div class="flex-grow-1 pa-1">
                <v-btn
                    height="40"
                    min-width="60"
                    depressed
                    block
                    class="elevation-0"
                    v-bind:color="'rgb(78, 82, 87)'"
                    v-on:click="showHint = !showHint">
                  <v-icon>mdi-head-question</v-icon>
                </v-btn>
              </div>
              <div class="flex-grow-1 pa-1">
                <v-btn
                    height="40"
                    min-width="60"
                    depressed
                    block
                    class="elevation-0"
                    v-bind:disabled="!hasInput && !hasSolutions"
                    v-bind:color="'rgb(78, 82, 87)'"
                    v-on:click="onUndo">
                  <v-icon>mdi-undo</v-icon>
                </v-btn>
              </div>

            </div>
          </div>
        </div>


        <template v-if="validEnd">
          Valid End
        </template>
        <template v-if="invalidEnd">
          Invalid End
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import OPERATORS from '@/constants/operators'

import Operators from '@/components/operators'
import NumberSelector from '@/components/number-selector'
import LiveEquation from '@/components/live-equation'
import SimpleNumber from "@/components/simple-number";

import moment from 'moment'
import AudioService from "@/services/audio";
import LicensePlate from "@/components/license-plate";

import shuffleSeed from 'shuffle-seed'

import {mapGetters} from "vuex";

export default {
  name: 'views-track-index',
  data() {
    return {
      availableNumbers: [],
      soundNewOutcome: AudioService.getSound('ding-1'),
      soundOutcomeScale1: AudioService.getSound('success-scale-1'),
      soundOutcomeScale2: AudioService.getSound('success-scale-2'),
      soundOutcomeScale3: AudioService.getSound('success-scale-3'),

      showHint: false,

      lastSolutionTime: undefined,
      leftNumberIndex: undefined,
      rightNumberIndex: undefined,
      operator: undefined,
      history: [],
      solutions: [],
      startTime: undefined,
      endTime: undefined
    }
  },
  props: {},
  watch: {
    validEnd: {
      handler(newValue) {
        if (newValue === true) {
          console.log('Job done');
          this.onCompleted();
        }
      }
    },
    outcome: {
      handler(newValue) {
        this.onNewOutcome(newValue);
      }
    }
  },
  methods: {
    onUndo() {
      if (this.hasInput) {
        this.resetCurrent();
      } else if (this.hasSolutions) {
        this.historyBack();
      }
    },
    onClickSolution(index) {
      this.solutions = this.solutions.slice(0, index);
      this.availableNumbers = this.history[index];
      this.history = this.history.slice(0, index);
      this.resetCurrent();
    },
    onCompleted() {
      this.endTime = moment();
      this.emitCompleted();
    },
    emitCompleted() {
      this.$emit('completed', {
        number: this.numberShuffled,
        duration: this.endTime.diff(this.startTime),
        solutions: this.solutions
      });
    },
    historyBack() {
      this.resetCurrent();
      this.availableNumbers = this.history.pop();
      this.solutions.pop();
    },
    resetAll() {
      this.resetCurrent();
      this.availableNumbers = this.history.shift();
      this.history = [];
      this.solutions = [];
    },
    resetCurrent() {
      this.leftNumberIndex = undefined;
      this.rightNumberIndex = undefined;
      this.operator = undefined;
      this.showHint = false;
    },
    onNewOutcome(outcome) {
      if (outcome === undefined) {
        return;
      }

      if (outcome % 1 != 0) {
        this.resetCurrent();
        return;
      }

      this.solutions.push({
        leftNumber: this.leftNumber,
        operator: this.operator,
        rightNumber: this.rightNumber,
        outcome: outcome,
        equation: [
          this.leftNumber,
          this.operator,
          this.rightNumber
        ].join(''),
        duration: moment().diff(this.lastSolutionTime)
      })
      this.history.push(this.availableNumbers.slice());

      this.availableNumbers = this.availableNumbers.filter((number, index) => {
        return index !== this.leftNumberIndex && index !== this.rightNumberIndex;
      });

      this.availableNumbers.push(outcome);
      this.lastSolutionTime = moment();
      this.resetCurrent();

      if (!this.validEnd) {
        switch (this.availableNumbers.length) {
          case 3:
            AudioService.getSound('success-scale-1').play();
            break;
          case 2:
            AudioService.getSound('success-scale-2').play();
            break;
          case 1:
            AudioService.getSound('success-scale-3').play();
            break;
          default:
            AudioService.getSound('success-scale-4').play();
            break;
        }
      }
    },
    onNumberSelected({number, index}) {
      if (this.leftNumberIndex === index) {
        this.leftNumberIndex = undefined;
      } else if (this.rightNumberIndex === index) {
        this.rightNumberIndex = undefined;
      } else if (this.leftNumber === undefined) {
        this.leftNumberIndex = index;
      } else if (this.rightNumber === undefined) {
        this.rightNumberIndex = index;
      }
    },
    onOperatorSelected(operator) {
      this.operator = operator;
    }
  },
  computed: {
    currentNumber() {
      return this.currentNumbers.filter((entry) => {
        return entry.number_key === this.$route.params.number;
      })[0];
    },
    possibleFormulas() {
      return this.currentNumber
          .formulas
          .map((formula) => {
            return [...formula.match(/(?<=\().*?(?=\))/g)];
          })
    },
    possibleSolutions() {
      return this.currentNumber
          .formulas
          .map((formula) => {
            formula = formula.replace(/[(]/g, '');
            formula = formula.split(')');
            return formula;
          });
    },
    currentSolution() {
      let result;

      if (this.solutions.length === 0) {
        result = this.possibleFormulas[0];
      } else {
        result = this.possibleFormulas
            .filter((formula) => {
              let noMatch = true;
              this.solutions.forEach((solution) => {
                if (formula.indexOf(solution.equation) === -1) {
                  noMatch = false;
                }
              });

              return noMatch;
            })
            .shift();
      }
      return result;
    },
    firstRemainingSolutionSteps() {
      return this.remainingSolutionSteps.shift();
    },
    remainingSolutionSteps() {
      if (!this.currentSolution) {
        return [];
      }

      let currentSolution = this.currentSolution;
      let solutions = this.solutions.slice(0);

      while (solutions.length > 0) {
        let solution = solutions.shift();
        let index = currentSolution.indexOf(solution.equation);
        currentSolution.splice(index, 1);
      }

      return currentSolution;
    },
    allNumbers() {
      let numbers = this.numberShuffled.slice(0);

      this.solutions.forEach((solution) => {
        numbers.push(solution.outcome);
      });

      return numbers;
    },
    usedNumbers() {
      let numbers = [];

      this.solutions.forEach((solution) => {
        numbers.push(solution.leftNumber);
        numbers.push(solution.rightNumber);
      });

      return numbers;
    },
    hasSolutions() {
      return this.solutions.length > 0;
    },
    hasInput() {
      return this.leftNumberIndex !== undefined || this.rightNumberIndex !== undefined || this.operator !== undefined;
    },
    validEnd() {
      let zerosOnly = true;
      this.availableNumbers.forEach((number) => {
        if (number !== 0) {
          zerosOnly = false;
        }
      });

      if (zerosOnly) {
        return true;
      }

      return this.availableNumbers.length === 1 && this.availableNumbers[0] === 0;
    },
    invalidEnd() {
      return this.availableNumbers.length === 1 && this.availableNumbers[0] !== 0;
    },
    selectedNumberIndexes() {
      return [
        this.leftNumberIndex,
        this.rightNumberIndex
      ]
    },
    leftNumber() {
      if (this.leftNumberIndex === undefined) {
        return;
      }

      return parseInt(this.availableNumbers[this.leftNumberIndex]);
    },
    rightNumber() {
      if (this.rightNumberIndex === undefined) {
        return;
      }

      return parseInt(this.availableNumbers[this.rightNumberIndex]);
    },
    outcome() {
      if (this.leftNumber === undefined || this.rightNumber === undefined || this.operator === undefined) {
        return;
      }

      switch (this.operator) {
        case OPERATORS.ADD:
          return this.leftNumber + this.rightNumber;
        case OPERATORS.SUBTRACT:
          return this.leftNumber - this.rightNumber;
        case OPERATORS.MULTIPLY:
          return this.leftNumber * this.rightNumber;
        case OPERATORS.DIVIDE:
          return this.leftNumber / this.rightNumber;
      }
    },
    number() {
      return shuffleSeed.shuffle(this.$route.params.number.split(','), this.currentTrack.seed);

    },
    numberShuffled() {
      return shuffleSeed.shuffle(this.currentNumber.number, this.currentTrack.seed);
    },
    ...mapGetters([
      'currentTrack',
      'currentNumbers'
    ])
  },
  created() {
    this.startTime = moment();
    this.lastSolutionTime = moment();
    this.availableNumbers = this.numberShuffled;
  },
  mounted() {
  },
  beforeDestroy() {
  },
  components: {
    SimpleNumber,
    LiveEquation,
    NumberSelector,
    Operators,
    LicensePlate
  },
  mixins: []
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.solution {
  cursor: pointer;
}
</style>
