<template>
  <div class="component license-plate shadow-sm" v-bind:style="{backgroundColor: theme.background, borderColor: theme.border}">
    <div class="top-left-sticker d-flex justify-center align-items-center">
      <div class="text">JUL</div>
    </div>

    <div class="top-right-sticker d-flex justify-center align-items-center">
      <div class="text mono-text">1965</div>
    </div>
    <div class="state-name">
      <span class="text" v-bind:style="{color: theme.state}">Arizona</span>
    </div>

    <div class="letters">
      <span class="text mono-text" v-bind:style="{color: theme.letters}">{{ contentDivided }}</span>
    </div>
  </div>
</template>

<script>

const THEMES = {
  ARIZONA: {
    background: '#7B282E',
    state: '#D5CDCE',
    letters: '#D5CDCE',
    border: '#333'
  },
  CALIFORNIA: {
    background: '#FFF',
    state: '#A53747',
    letters: '#03158D',
    border: '#333'
  },
  NEW_YORK: {
    background: '#F0B651',
    state: '#0B2A5A',
    letters: '#0B2A5A',
    border: '#0B2A5A'
  }
};

export default {
  name: 'components-license_plate',
  data() {
    return {}
  },
  props: {
    theme: {
      default() {
        return THEMES.NEW_YORK;
        return {};
      }
    },
    content: {
      required: true
    }
  },
  watch: {},
  methods: {},
  computed: {
    contentDivided() {
      let halfWayIndex = Math.floor(this.content.length / 2);
      let letters = this.content.split('');

      let result = [];

      letters.forEach((letter, index) => {
        if(index === halfWayIndex) {
          result.push('-')
        }
        result.push(letter);
      })

      return result.join('');
    }
  },
  created() {

  },
  mounted() {
  },
  components: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Share+Tech+Mono&display=swap');

.license-plate {
  height: 15em;
  width: 30em;
  border-radius: 1em;
  background: black;
  position: relative;
  display: inline-block;
  border: .5em solid #333;
  overflow: hidden;

  .mono-text {
    font-family: 'Share Tech Mono', monospace;
  }

  .top-left-sticker {
    background: #C2C0C1;
    top: 1em;
    width: 5em;
    height: 3em;
    left: 1em;
    position: absolute;
    transform: rotate(-5deg);

    .text {
      font-size: 2em;
      font-weight: bold;
      color: #353B82;
    }
  }

  .top-right-sticker {
    background: #DE9867;
    top: 1em;
    width: 5em;
    height: 3em;
    right: 1em;
    position: absolute;
    transform: rotate(8deg);

    .text {
      font-size: 1em;
      font-weight: bold;
      color: #704533;
    }
  }

  .state-name {
    position: absolute;
    font-weight: 600;
    text-align: center;
    width: 100%;
    top: .5em;

    .text {
      color: #F2CD3A;
      font-size: 2em;
      letter-spacing: .15em;
      text-transform: uppercase;
    }
  }

  .letters {
    position: absolute;
    top: 5.5em;
    text-align: center;
    width: 100%;

    .text {
      text-transform: uppercase;
      color: #F2CD3A;
      font-size: 5em;
      font-weight: 600;
      transform: scale(1,1.75);
      display: inline-block;
    }
  }
}
</style>
