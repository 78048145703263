<template>
  <div class="position-relative animated-view">

    <template v-for="pocket in animationPockets">
      <div class="animation-pocket" v-bind:style="pocket.style">
        <template v-if="pocket.index === animationPocketRoad.index">
          <animated-asset v-bind:parent-height="height"
                          v-bind:parent-width="width + (animationPocketWidth * scale)"
                          v-bind:loop="true"
                          v-bind:randomize="false"
                          v-bind:duration="duration * 3"
                          v-bind:reverse="true"
                          v-bind:auto-start="true"
          >
            <car v-bind:loop="true"
                 v-bind:bounce="5 * scale"
            >
              <img v-bind:src="car2.src"
                   v-bind:width="carStyle.width"
                   style="position: relative; top: 10px"
                   class="car"
              >
            </car>
          </animated-asset>
        </template>
        <template v-else>
          <animation-pocket v-bind:pocket="pocket"
                            v-bind:scale="scale"
                            v-bind:road-side-assets="pocket.roadSideAssets"
                            v-bind:width="animationPocketWidth"
                            v-bind:canvas-width="width"
                            v-bind:canvas-height="height"
                            v-bind:duration="duration"
                            v-bind:asset-count="animatesAssetsPerPocket"
          ></animation-pocket>
        </template>
      </div>
    </template>

    <template v-for="n in roadSegmentCount">
      <road-segment v-bind:style="roadSegment(n)"
                    v-bind:duration="900"
                    class="road-segment">
        <img v-bind:src="road.src" v-bind:width="roadSegment(n).width"/>
      </road-segment>
    </template>

    <car v-bind:loop="true"
         v-bind:bounce="5 * scale"
         style="z-index: 1000"
    >
      <img v-bind:src="car.src"
           v-bind:style="carStyle"
           class="car player"
      >
    </car>
  </div>
</template>

<script>
import AnimatedAsset from './animated-asset'
import AnimationPocket from './animation-pocket'
import RoadSegment from './road-segment'
import Car from './car'

const Fat = require('@/libraries/fat.min')

export default {
  name: 'components-animated_view',
  data() {
    return {
      animationPocketHeight: 65,
      animationPocketWidth: 30,
      animatesAssetsPerPocket: 2,

      animationPockets: [],
      animationPocketRoad: undefined,
      animationPocketRoadIndex: undefined,
      roadWidth: 120,
      roadHeight: 69.19,

      duration: 4000,
      roadOffsetTop: -80,
      trees: [],
      road: {
        src: require('../../../../assets/road.svg'),
        width: 120
      },
      car: {
        src: require('../../../../assets/car-1.svg'),
        width: 30
      },
      car1: {
        src: require('../../../../assets/car-1.svg'),
        width: 30
      },
      car2: {
        src: require('../../../../assets/car-2.svg'),
        width: 30
      },
      house1: {
        src: require('../../../../assets/house-1.svg'),
        width: 55
      },
      house2: {
        src: require('../../../../assets/house-2.svg'),
        width: 55
      },
      house3: {
        src: require('../../../../assets/house-3.svg'),
        width: 55
      },
      sign1: {
        src: require('../../../../assets/sign-1.svg'),
        width: 55
      },
      tree1: {
        src: require('../../../../assets/tree-1.svg'),
        width: 40
      },
      tree2: {
        src: require('../../../../assets/tree-2.svg'),
        width: 40
      },
      tree3: {
        src: require('../../../../assets/tree-3.svg'),
        width: 40
      },
      tree4: {
        src: require('../../../../assets/tree-4.svg'),
        width: 40
      },
      tree5: {
        src: require('../../../../assets/tree-5.svg'),
        width: 40
      },
      tree6: {
        src: require('../../../../assets/tree-6.svg'),
        width: 40
      },
      tree7: {
        src: require('../../../../assets/tree-7.svg'),
        width: 40
      },
      tree8: {
        src: require('../../../../assets/tree-8.svg'),
        width: 40
      }
    }
  },
  props: {
    height: {
      required: true,
    },
    width: {
      required: true,
    },
    scale: {
      default() {
        return 1;
      }
    }
  },
  watch: {},
  methods: {
    setAnimationPockets() {
      let pockets = [];
      let scaledAnimationPocketHeight = this.animationPocketHeight * this.scale;
      let scaledAnimationPocketWidth = this.animationPocketWidth * this.scale;

      let pocketCount = Math.ceil((this.height / scaledAnimationPocketHeight) * 3);
      let currentTop = -this.height;

      this.animationPocketRoadIndex = Math.floor(pocketCount / 3) + 1;

      let index = 0;
      while (index < pocketCount) {
        let style = {
          width: `${scaledAnimationPocketWidth}px`,
          height: `${scaledAnimationPocketHeight}px`,
          top: `${currentTop}px`,
          zIndex: currentTop > 0 ? currentTop : 0
        };

        let assets = [];
        for (let assetIndex = 0; assetIndex < this.animatesAssetsPerPocket; assetIndex++) {
          let ref = `pocket_${index}_asset_${assetIndex}`
          assets.push({
            asset: this.randomTree(),
            ref: ref
          });
        }

        pockets.push({
          index: index,
          style: style,
          top: currentTop,
          currentAssetIndex: 0,
          roadSideAssets: index === this.animationPocketRoadIndex - 1
        })
        index++;
        currentTop += scaledAnimationPocketHeight;
      }

      this.animationPocketRoad = pockets[this.animationPocketRoadIndex];
      this.animationPocketRoad.style.background = 'yellow';

      this.animationPockets = pockets;
    },

    roadSegment(index) {
      let roadWidth = this.roadWidth * this.scale;
      let roadHeight = this.roadHeight * this.scale;

      let offsetTop = this.animationPocketRoad.top - (roadHeight / 2);
      let offsetRight = -roadWidth;

      let top = offsetTop + ((roadHeight / 2) * (index - 2));
      let right = offsetRight + ((roadWidth / 2) * (index - 2));

      return {
        width: `${roadWidth * this.scale}px`,
        top: `${top * this.scale}px`,
        right: `${right * this.scale}px`
      }
    },
    generateTrees() {
      this.trees = [];

      for (let i = 0; i < 25; i++) {
        this.trees.push(this.randomTree());
      }
    },
    randomRoadAsset() {
      let assets = [
        this.house1,
        this.house2,
        this.house3
      ]

      return assets[Math.floor(assets.length * Math.random())];
    },
    randomTree() {
      let assets = [
        this.tree1,
        this.tree2,
        this.tree3,
        this.tree4,
        this.tree5,
        this.tree6,
        this.tree7,
        this.tree8
      ]

      return assets[Math.floor(assets.length * Math.random())];
    },
    start() {
      this.generateTrees();
    }
  },
  computed: {
    carStyle() {
      return {
        // top: `${165 * this.scale}px`,
        top: `${(this.height / 2)}px`,
        // left: `${200 * this.scale}px`,
        left: `${this.width / 2}px`,
        width: `${this.car.width * this.scale}px`
      }
    },
    roadSegmentCount() {
      return Math.ceil(this.width / (this.roadWidth * this.scale)) + 8;
    }
  },
  created() {
    this.setAnimationPockets();
  },
  mounted() {
    this.start();
  },
  beforeDestroy() {

  },
  components: {
    RoadSegment,
    AnimationPocket,
    AnimatedAsset,
    Car
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.animated-view {
  overflow: hidden;
}

.animation-pocket {
  position: absolute;
  left: 100%;
  background: red;
}

.road-segment {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
}

.car {
  position: absolute;
  z-index: 5;
  &.player {
    z-index: 10;
  }
}
</style>
