<template>
  <div class="component w-100 h-100">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'views-track-index',
  data() {
    return {}
  },
  props: {},
  watch: {},
  created() {

  },
  mounted() {
  },
  methods: {},
  computed: {},
  components: {},
  mixins: []
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
