<template>
  <div class="component">
    <v-chip>{{ sum }}</v-chip>
  </div>
</template>

<script>
import OPERATORS from '@/constants/operators'
import ScoreService from '@/services/score'

export default {
  name: 'components-result_solutions',
  data() {
    return {
      ScoreService
    }
  },
  props: {
    solutions: {
      required: true,
      type: Array
    }
  },
  watch: {},
  methods: {},
  computed: {
    sum() {
      let sum = 0;
      ScoreService.solutionsScore(this.solutions).forEach((score) => {
        sum += score.sum;
      })

      return sum;
    }
  },
  created() {

  },
  mounted() {
  },
  components: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>