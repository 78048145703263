<template>
  <div class="component">
    <v-card class="mb-3">
      <v-card-text>
        <div class="text-center pa-5">
          <span class="total-sum">{{ totalSum }}</span>
        </div>
      </v-card-text>
    </v-card>
    <template v-for="numberResult in numberResults">
      <result-card class="mb-5" v-bind:result="numberResult"></result-card>
    </template>
  </div>
</template>

<script>
import ResultCard from "@/components/result-card";
import _get from "lodash/get";
import Fixture from "@/views/fixture.json";
import ScoreService from "@/services/score";

export default {
  name: 'components-',
  data() {
    return {
      ScoreService
    }
  },
  props: {
    trackResult: {
      required: true
    }
  },
  watch: {},
  methods: {},
  computed: {
    numberResults() {
      return _get(this.trackResult, 'numberResults');
    },
    solutionsScore() {
      return ScoreService.trackScore(this.numberResults);
    },
    operatorBonusSum() {
      let sum = 0;
      this.solutionsScore.forEach((solutionScore) => {
        sum += solutionScore.sum;
      });

      return sum;
    },
    totalSum() {
      let sum = 0;
      this.solutionsScore.forEach((solutionScore) => {
        sum += solutionScore.sum;
      });

      return sum;
    },
  },
  created() {

  },
  mounted() {
  },
  components: {
    ResultCard
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.total-sum {
  font-size: 3em;
  font-weight: bold;
}
</style>
