<template>
  <div class="component">
    <template v-if="processingAuth">
      <v-progress-circular indeterminate size="70"/>
      Login successful!
    </template>
    <template v-else>
      <div ref="auth-widget"/>
    </template>

    <template v-if="loadingWidget">
      Loading the widget
    </template>
  </div>
</template>

<script>
import firebase from 'firebase/app';
import {mapMutations} from "vuex";

var firebaseui = require('firebaseui');

export default {
  name: 'components-login_form',
  data() {
    return {
      ui: undefined,
      loadingWidget: false,
      processingAuth: false
    }
  },
  props: {},
  watch: {},
  methods: {
    initAuthWidget() {
      this.loadingWidget = true;
      let element = this.$refs['auth-widget'];


      if (window.firebaseUI.isPendingRedirect()) {
        this.processingAuth = true;
      }

      firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
          .then(() => {
            window.firebaseUI.start(element, {
              callbacks: {
                signInSuccessWithAuthResult: (authResult, redirectUrl) => {
                  this.setUser(authResult.user);

                  let redirectPath = this.$route.query.redirect ? this.$route.query.redirect : '/';
                  this.$router.replace(redirectPath);
                  return false;
                },
                uiShown: () => {
                  this.loadingWidget = false;
                }
              },
              signInSuccessUrl: '/',
              signInOptions: [
                // List of OAuth providers supported.
                firebase.auth.GoogleAuthProvider.PROVIDER_ID,
                // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
                // firebase.auth.TwitterAuthProvider.PROVIDER_ID,
                // firebase.auth.GithubAuthProvider.PROVIDER_ID
              ],
              // Other config options...
            });
          })
    },
    ...mapMutations(['setUser'])
  },
  computed: {},
  created() {
    if (!window.firebaseUI) {
      window.firebaseUI = new firebaseui.auth.AuthUI(firebase.auth());
    }
  },
  mounted() {
    this.initAuthWidget();
  },
  beforeDestroy() {
    // this.ui.destroy();
  },
  components: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
