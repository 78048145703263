export default {
    firebase: {
        apiKey: "AIzaSyBNcHRUWwdT6EAwSsv0xm-QhgJNBYnxnGE",
        authDomain: "licensetozero-14232.firebaseapp.com",
        databaseURL: "https://licensetozero-14232-default-rtdb.firebaseio.com",
        projectId: "licensetozero-14232",
        storageBucket: "licensetozero-14232.appspot.com",
        messagingSenderId: "211165248191",
        appId: "1:211165248191:web:7ec6ee660e27b51673640a",
        measurementId: "G-YESR71M98K"
    }
}