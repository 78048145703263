<template>
  <div class="component timer-radial">
    <v-progress-circular v-bind:size="size"
                         v-bind:rotate="-90"
                         color="white"
                         v-bind:value="percentage">
      <slot>
        {{ formattedValue }}
      </slot>
    </v-progress-circular>
    <div class="debug position-absolute top-0" v-if="debug">
      <div>Percentage: {{ percentage }}</div>
      <div>Min: {{ min }}</div>
      <div>Max: {{ max }}</div>
      <div>value: {{ value }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'components-',
  data() {
    return {}
  },
  props: {
    debug: undefined,
    value: undefined,
    formatter: {
      type: Function
    },
    size: {
      default() {
        return 50;
      }
    },
    min: {
      required: true
    },
    max: {
      required: true
    }
  },
  watch: {},
  methods: {},
  computed: {
    formattedValue() {
      if (this.formatter) {
        return this.formatter(this.value);
      }

      return this.value;
    },
    percentage() {
      let value = this.value > this.max ? this.max : this.value;
      let range = this.max - this.min;
      let percentage = value / range;

      return percentage * 100;
    }
  },
  created() {

  },
  mounted() {
  },
  components: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
